<div>
  <div class="popup">
    <a class="close" mat-button mat-dialog-close>&times;</a>
    <div class="row p-0 m-0">
      <div class="col-sm-6 p-0">
        <img loading="lazy" [src]="src" />
      </div>
      <div class="col-sm-6">
        <div class="sidebar">
          <div class="block clearfix">
            <h3 class="ab_sec_heading-pop mb-4"><b>Submit Your Details</b></h3>
            <form
              class="my_detail"
              [formGroup]="formData"
              enctype="multipart/form-data"
            >
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="name">Full Name</label>
                    <input
                      type="text"
                      class="form-control-job user_icon"
                      formControlName="Name"
                      id="name"
                      (keypress)="onSpace($event)"
                    />
                    <div
                      id="formError"
                      *ngIf="fullName && fullName.invalid && fullName.touched "
                    >
                      <span *ngIf="fullName.errors?.['required']"> <i class="fa fa-exclamation-triangle" style="color: #fe3061;" aria-hidden="true"></i> Required </span>
                      <span
                        *ngIf="fullName.errors?.['pattern'] && !fullName.errors?.['required']"
                      > <i class="fa fa-exclamation-triangle" style="color: #fe3061;" aria-hidden="true"></i>
                        Invalid Name input
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="email">Email</label>
                    <input
                      type="email"
                      class="form-control-job mail_icon"
                      formControlName="Email"
                      id="mail"
                    />
                    <div
                      id="formError"
                      *ngIf="email && email.invalid && email.touched "
                    >

                      <span *ngIf="email.errors?.['required']"><i class="fa fa-exclamation-triangle" style="color: #fe3061;" aria-hidden="true"></i> Required </span>
                      <span
                        *ngIf="email.errors?.['pattern'] && !email.errors?.['required']"
                      >
                      <i class="fa fa-exclamation-triangle" style="color: #fe3061;" aria-hidden="true"></i> Invalid Email Input
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="text">Phone Number</label>
                    <input
                      type="text"
                      class="form-control-job phone_icon"
                      formControlName="Number"
                      id="Number"
                    />
                    <div
                      id="formError"
                      *ngIf="contact && contact.invalid && contact.touched"
                    >
                      <span *ngIf="contact.errors?.['required']"> <i class="fa fa-exclamation-triangle" style="color: #fe3061;" aria-hidden="true"></i> Required </span>
                      <span
                        *ngIf="contact.errors?.['pattern'] && !contact.errors?.['required']"
                      >
                      <i class="fa fa-exclamation-triangle" style="color: #fe3061;" aria-hidden="true"></i> Invalid input
                      </span>
                      <span
                        *ngIf="(contact.errors?.['minlength'] || contact.errors?.['maxlength']) && !contact.errors?.['pattern']"
                      >
                      <i class="fa fa-exclamation-triangle" style="color: #fe3061;" aria-hidden="true"></i> Phone Number Must be of 10 Digit
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div _ngcontent-wor-c20="" class="row">
                <div _ngcontent-wor-c20="" class="col-sm-12">
                  <div _ngcontent-wor-c20="" class="form-group">
                    <label _ngcontent-wor-c20="" for="file"
                      >Upload Resume (*.doc,*docx,*.pdf)</label
                    >
                    <input
                      _ngcontent-wor-c20=""
                      type="file"
                      formControlName="Resume"
                      id="file_1"
                      name="file_1"
                      (change)="handleFileInput($any($event).target.files)"
                      required="required"
                      class="form-control-job upload_resume_icon"
                    />
                    <div
                      id="formError" class="err"
                      *ngIf="resume && resume.invalid && resume.touched"
                    >
                      <span *ngIf="resume.errors?.['required'] && !resume.errors?.['format']"> <i class="fa fa-exclamation-triangle" style="color: #fe3061;" aria-hidden="true"></i> Required </span>
                      <span *ngIf="resume.errors?.['format']"
                        > <i class="fa fa-exclamation-triangle" style="color: #fe3061;" aria-hidden="true"></i> This File Format Is Not Allowed</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="alert alert-success"
                id="success"
                style="display: none"
              ></div>
              <div
                class="alert alert-danger"
                id="failed"
                style="display: none"
              ></div>
              <input
                type="button"
                (click)="onSubmit()"
                [disabled]="formData.invalid"
                value="Submit"
                class="submit-button btn btn-default"
              />
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="spinner" *ngIf="isunning">
      <loading-spinner></loading-spinner>
    </div>
  </div>
</div>
