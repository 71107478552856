import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { APIcalls } from '../Services/api';
import { APIService } from '../Services/main-service.service';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {
  data: any;

  constructor( private api : APIService, private title : Title ) { }

  ngOnInit(): void {
    this.title.setTitle('Portfolio | Resiliencesoft')
    this.api.getData(APIcalls.getApi.Portfolio).subscribe((res)=>{
      this.data = res.data
    })
  }

}
