import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule, Router, NavigationStart } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { CallBackComponent } from './call-back/call-back.component';
import { CareersComponent } from './careers/careers.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { GalleryComponent } from './gallery/gallery.component';

import { HomeComponent } from './home/home.component';
import { PaymentComponent } from './payment/payment.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { ThankYouComponent } from './payment/thank-you/thank-you.component';
import { MeettheteamComponent } from './meettheteam/meettheteam.component';
import { OurProductsComponent } from './our-products/our-products.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { CommonserviceComponent } from './commonservice/commonservice.component';
import { UserReviewComponent } from './user-review/user-review.component';
import { internshipRouting } from './SEO_Constant/routes.constant';
import { InternshipComponent } from './internship/internship.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ContractualServiceComponent } from './contractual-service/contractual-service.component';

@Component({
  'template': ``,
  'styles': [``]
})
export class pnf {
  constructor(private router: Router){
    let goto = '/';
    routes.map((r)=> {

      if((r.path)?.toLowerCase() === (router.url).toLowerCase()){
        goto = '/'+r.path
      }
    })
    router.navigate([goto])
  }
}


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
  },
  {
    path: 'payment',
    component: PaymentComponent,
  },
  {
    path: 'payment/success',
    component: ThankYouComponent,
  },
  {
    path: 'careers',
    component: CareersComponent,
  },
  {
    path: 'portfolio',
    component: PortfolioComponent,
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
  },
  {
    path: 'call-back',
    component: CallBackComponent,
  },
  {
    path: 'gallery',
    component: GalleryComponent,
  },
  {
    path: 'meettheteam',
    component: MeettheteamComponent,
  },
  {
    path: 'ourproducts',
    component: OurProductsComponent
  },
  {
    path: 'ourproducts/:name',
    component: ProductDetailsComponent
  },
  {
    path: 'review',
    component: UserReviewComponent
  },
  {
    path: 'privacy',
    component: PrivacyComponent
  },
  {
    path: 'contractual-service',
    component: ContractualServiceComponent,
  },
  // {
  //   path: "internship",
  //   component: InternshipComponent
  // },
  {
    path: 'service',
    loadChildren: () =>
    import('./service/service.module').then((res) => res.ServiceModule),
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    useHash: false,
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabledBlocking'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor () {

    Object.keys(internshipRouting).map(key=> {
      routes.push({
        path: (key as string).replace(/ /g, '-'),
        component: internshipRouting[key].component
      })
    })
    routes.push(
      {
        path: ':any',
        component: CommonserviceComponent,
      },
      {
        path: '**',
        component: pnf
      })
    }
}
