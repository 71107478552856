import { Component, OnInit ,ViewChild} from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css']
})
export class CareersComponent implements OnInit {
  displayStyle = "none";
  constructor( private title : Title ) {}

  ngOnInit(): void {
    this.title.setTitle('Careers | Resiliencesoft')
  }

  @ViewChild('myModal') myModal: any;

  closeModel() {
    let modal_t  = document.getElementById('popup1') as any

    modal_t.classList.remove('show')
    modal_t.classList.add('hidden');
    // modal_t.classList.remove('hidden')

 }

  openModel() {
    (document.getElementById("popup1") as any ).click();
  }
  onSpace(event:any){
    if(event.keyCode===32 && !event.target.value) return false;
    return true;
    }
}
