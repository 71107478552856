<!-- Slider -->
<section class="slider-section isDesktop">
  <div
    id="carousel_1"
    class="carousel slide carousel_position"
    data-ride="carousel_1"
  >
    <!-- Carousel Content -->
    <div class="carousel-inner" role="listbox">
      <div
        class="carousel-item active carousel-zoom animate__backInRight text-center position-relative"
      >
        <img
          loading="lazy"
          class="w-100"
          src="https://images.prismic.io/kurmishadi/7b74c330-8aaa-4ba5-9b07-7e0e3f6b0028_technologis.webp?auto=compress,format=webp,height=584,width=1358"
          alt="resiliencesoft"
        />
        <!-- style="background-image: url('https://images.prismic.io/kurmishadi/7b74c330-8aaa-4ba5-9b07-7e0e3f6b0028_technologis.webp?auto=compress,format=webp,height=584,width=1358');" -->
        <div class="container">
          <div class="Banner-caption position-absolute">
            <h2>Expert on These Technologies</h2>
            <p>
              Resiliencesoft have experts on these technologies, and it grows
              your business expertise.
            </p>
            <app-consult-now-modal
              class="mt-5"
              id="welcomedialog"
            ></app-consult-now-modal>
          </div>
        </div>
      </div>

      <div class="carousel-item text-center position-relative">
        <img
          loading="lazy"
          class="w-100"
          src="https://images.prismic.io/kurmishadi/b3a3d04e-ed16-4eb2-9a26-66f38efb0e7d_app-development.webp?auto=compress,format=webp,height=584,width=1358"
          alt="resiliencesoft"
        />
        <!-- style="background-image: url('https://images.prismic.io/kurmishadi/b3a3d04e-ed16-4eb2-9a26-66f38efb0e7d_app-development.webp?auto=compress,format=webp,height=584,width=1358');" -->
        <div class="container">
          <div class="Banner-caption position-absolute">
            <h2>Business with Own Mobile App</h2>
            <p>
              ResilienceSoft is a great place to find your business purpose with
              many types of mobile applications.
            </p>
            <app-consult-now-modal
              class="mt-5"
              id="welcomedialog"
            ></app-consult-now-modal>
          </div>
        </div>
      </div>

      <div class="carousel-item text-center position-relative">
        <img
          loading="lazy"
          class="w-100"
          src="https://images.prismic.io/kurmishadi/2841e4c5-4fad-407d-8ad8-6530c9c0a161_hosting.webp?auto=compress,format=webp,height=584,width=1358"
          alt="resiliencesoft"
        />
        <!-- style="background-image: url('https://images.prismic.io/kurmishadi/2841e4c5-4fad-407d-8ad8-6530c9c0a161_hosting.webp?auto=compress,format=webp,height=584,width=1358');" -->
        <div class="container">
          <div class="Banner-caption position-absolute">
            <h2>Get Web Hosting Solution</h2>
            <p>
              You can achieve your business goal with resiliencesoft using web
              hosting solution.
            </p>
            <app-consult-now-modal
              class="mt-5"
              id="welcomedialog"
            ></app-consult-now-modal>
          </div>
        </div>
      </div>

      <div class="carousel-item text-center position-relative">
        <img
          loading="lazy"
          class="w-100"
          src="https://images.prismic.io/kurmishadi/70e155c6-1c84-4c11-aec3-1162caac70a1_social-media-marketing-banner.webp?auto=compress,format=webp,height=584,width=1358"
          alt="resiliencesoft"
        />
        <!-- style="background-image: url('https://images.prismic.io/kurmishadi/70e155c6-1c84-4c11-aec3-1162caac70a1_social-media-marketing-banner.webp?auto=compress,format=webp,height=584,width=1358');" -->
        <div class="container">
          <div class="Banner-caption position-absolute">
            <h2>Go With Social Media Marketing</h2>
            <p>
              Resiliencesoft gives you great resource for growing your business
              through social media marketing.
            </p>
            <app-consult-now-modal
              class="mt-5"
              id="welcomedialog"
            ></app-consult-now-modal>
          </div>
        </div>
      </div>
    </div>

    <!-- Previous & Next -->
    <div class="slider_buttons">
      <a
        href="#carousel_1"
        class="carousel-control-prev"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only"></span>
      </a>
      <a
        href="#carousel_1"
        class="carousel-control-next"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only"></span>
      </a>
    </div>
  </div>
  <!-- End of Carousel -->
</section>
<!-- End of Slider -->

<section class="slider-section isMobile">
  <div id="carousel_2" class="carousel slide" data-ride="carousel_2">
    <!-- mobile-view-banner  start -->
    <div class="carousel-inner" role="listbox">
      <div
        class="carousel-item active carousel-zoom animate__backInRight text-center position-relative"
      >
        <img
          loading="lazy"
          class="w-100 mt-4"
          src="/assets/images/mobile-home-banner-design-2.webp"
          alt="resiliencesoft"
        />
        <div class="container">
          <div class="Banner-caption position-absolute" style="left: 0">
            <h2>Expert on These Technologies</h2>
            <!-- <p>Resiliencesoft have experts on these technologies, and it grows your business expertise.</p> -->
            <app-consult-now-modal
              class="mt-5"
              id="welcomedialog"
            ></app-consult-now-modal>
          </div>
        </div>
      </div>

      <div class="carousel-item text-center position-relative">
        <img
          loading="lazy"
          class="w-100 mt-4"
          src="/assets/images/mobile-home-banner-design-1.webp"
          alt="resiliencesoft"
        />
        <div class="container">
          <div class="Banner-caption position-absolute">
            <h2>Business with Own Mobile App</h2>
            <!-- <p>ResilienceSoft is a great place to find your business purpose with many types of mobile applications.</p> -->
            <app-consult-now-modal
              class="mt-5"
              id="welcomedialog"
            ></app-consult-now-modal>
          </div>
        </div>
      </div>

      <div class="carousel-item text-center position-relative">
        <img
          loading="lazy"
          class="w-100 mt-4"
          src="/assets/images/mobile-home-banner-design-3.webp"
          alt="resiliencesoft"
        />
        <div class="container">
          <div class="Banner-caption position-absolute">
            <h2>Get Web Hosting Solution</h2>
            <!-- <p>You can achieve your business goal with resiliencesoft using web hosting solution.</p> -->
            <app-consult-now-modal
              class="mt-5"
              id="welcomedialog"
            ></app-consult-now-modal>
          </div>
        </div>
      </div>

      <div class="carousel-item text-center position-relative">
        <img
          loading="lazy"
          class="w-100 mt-4"
          src="/assets/images/mobile-home-banner-design-4.webp"
          alt="resiliencesoft"
        />
        <div class="container">
          <div class="Banner-caption position-absolute">
            <h2>Go With Social Media Marketing</h2>
            <!-- <p>Resiliencesoft gives you great resource for growing your business through social media marketing.</p> -->
            <app-consult-now-modal
              class="mt-5"
              id="welcomedialog"
            ></app-consult-now-modal>
          </div>
        </div>
      </div>
    </div>
    <!-- mobile-view-banner end-->
    <div class="slider_buttons">
      <a
        href="#carousel_2"
        class="carousel-control-prev"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only"></span>
      </a>
      <a
        href="#carousel_2"
        class="carousel-control-next"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only"></span>
      </a>
    </div>
  </div>
</section>

<!-- -------------------------Banner Section Start----------------- -->

<!-- -------------------------Services Section Start----------------- -->

<section class="sscp40 pt-2">
  <div class="container">
    <div class="section_heading">
      <h2>We Shape The Perfect Solutions</h2>
      <p>
        We are committed to provide our customers with exceptional service while
        offering our employees the best training.
      </p>
    </div>

    <div class="row mt-5 pt-3">
      <div class="col-lg-2 col-md-4 col-sm-6 col-6">
        <div
          class="service_item animate__animated show-on-scroll animate__bounceIn"
          routerLink="service/website-Design"
          animate-order="1"
        >
          <img
            loading="lazy"
            class="service_icon"
            src="https://images.prismic.io/kurmishadi/0d4f835c-2904-410a-a9a3-6f78aba1d68e_web-design.png?auto=compress,format=webp,height=60,width=60"
            alt="Web Designing"
          />
          <!-- <span class="service_name">Web Designing</span> -->
          <li class="service_name"><a> Web Designing</a></li>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-6 col-6">
        <div
          class="service_item animate__animated show-on-scroll animate__bounceIn"
          routerLink="service/Web-Application-Development"
        >
          <img
            loading="lazy"
            class="service_icon"
            src="https://images.prismic.io/kurmishadi/c4c03523-ced2-46cb-a731-a0f75fb6a2f6_web-development.png?auto=compress,format=webp,height=60,width=60"
            alt="Web-development"
          />
          <!-- <span class="service_name">Web Development</span> -->
          <li class="service_name"><a>Web Development</a></li>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-6 col-6">
        <div
          class="service_item animate__animated show-on-scroll animate__bounceIn"
          routerLink="service/EcommerceSolution"
        >
          <img
            loading="lazy"
            class="service_icon"
            src="https://images.prismic.io/kurmishadi/30f9a5d7-534e-422a-9802-c9ccba1d6d94_bag.png?auto=compress,format=webp,height=60,width=60"
            alt="Bag"
          />
          <!-- <span class="service_name">E-Commerce Solution</span> -->
          <li class="service_name"><a>E-Commerce Solution</a></li>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-6 col-6">
        <div
          class="service_item animate__animated show-on-scroll animate__bounceIn"
          routerLink="service/web-Hosting-Solutions"
        >
          <img
            loading="lazy"
            class="service_icon"
            src="https://images.prismic.io/kurmishadi/75069fbe-97cc-4b4c-8550-8f08e98d2387_cloud.png?auto=compress,format=webp,height=60,width=60"
            alt="Cloud pic"
          />
          <!-- <span class="service_name">Hosting Services</span> -->
          <li class="service_name"><a>Hosting Services</a></li>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-6 col-6">
        <div
          class="service_item animate__animated show-on-scroll animate__bounceIn"
          routerLink="service/digital-Media-Marketing"
        >
          <img
            loading="lazy"
            class="service_icon"
            src="https://images.prismic.io/kurmishadi/243de374-b89a-4bd7-9c0b-496bae48cdf4_social-media.png?auto=compress,format=webp,height=60,width=60"
            alt="Social-media"
          />
          <!-- <span class="service_name">Digital Marketing</span> -->
          <li class="service_name"><a>Digital Marketing</a></li>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-6 col-6">
        <div
          class="service_item animate__animated show-on-scroll animate__bounceIn"
          routerLink="service/bulkSms"
        >
          <img
            loading="lazy"
            class="service_icon"
            src="https://images.prismic.io/kurmishadi/3afd070c-0a86-467b-a68a-27891bd892f9_sms.png?auto=compress,format=webp,height=60,width=60"
            alt="service"
          />
          <!-- <span class="service_name">SMS Services</span> -->
          <li class="service_name"><a>SMS Services</a></li>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-2 col-md-4 col-sm-6 col-6">
        <div
          class="service_item animate__animated show-on-scroll animate__bounceIn"
          routerLink="service/website-Design"
        >
          <img
            loading="lazy"
            class="service_icon"
            src="https://images.prismic.io/kurmishadi/06ca3a24-a18c-4c65-bb1e-cca1a2a82a3b_monitor.png?auto=compress,format=webp,height=60,width=60"
            alt="Website Designing"
          />
          <!-- <span class="service_name">Website Re-Design</span> -->
          <li class="service_name"><a>Website Re-Design</a></li>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-6 col-6">
        <div
          class="service_item animate__animated show-on-scroll animate__bounceIn"
          routerLink="service/website-Design"
        >
          <img
            loading="lazy"
            class="service_icon"
            src="https://images.prismic.io/kurmishadi/1ade9023-0c81-4ad7-8d61-9f718dd8a755_devices.png?auto=compress,format=webp,height=60,width=60"
            alt="responsive website"
          />
          <!-- <span class="service_name">Responsive Website</span> -->
          <li class="service_name"><a>Responsive Website</a></li>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-6 col-6">
        <div
          class="service_item animate__animated show-on-scroll animate__bounceIn"
          routerLink="service/android-app-development"
        >
          <img
            loading="lazy"
            class="service_icon"
            src="https://images.prismic.io/kurmishadi/26af67ef-fd85-48f6-ae1d-0b9c3006f191_apps.png?auto=compress,format=webp,height=60,width=60"
            alt="Mobile"
          />
          <!-- <span class="service_name">App Development</span> -->
          <li class="service_name"><a>App Development</a></li>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-6 col-6">
        <div
          class="service_item animate__animated show-on-scroll animate__bounceIn"
          routerLink="service/bulk-Sms-and-Email-Marketing"
        >
          <img
            loading="lazy"
            class="service_icon"
            src="https://images.prismic.io/kurmishadi/31fa9ad8-bc17-4d7f-9fa3-fa80f3cfc06b_email_next.png?auto=compress,format=webp,height=60,width=60"
            alt="email"
          />
          <!-- <span class="service_name">E-mail Services</span> -->
          <li class="service_name"><a>E-mail Services</a></li>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-6 col-6">
        <div
          class="service_item animate__animated show-on-scroll animate__bounceIn"
          routerLink="service/search-Engine-Optimisation"
        >
          <img
            loading="lazy"
            class="service_icon"
            src="https://images.prismic.io/kurmishadi/6d7714cd-2070-45e5-8423-0bf2838ca2bd_seo.png?auto=compress,format=webp,height=60,width=60"
            alt="seo"
          />
          <!-- <span class="service_name">SEO Solution</span> -->
          <li class="service_name"><a>SEO Solution</a></li>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-6 col-6">
        <div
          class="service_item animate__animated show-on-scroll animate__bounceIn"
          routerLink="service/DataEntry"
        >
          <img
            loading="lazy"
            class="service_icon"
            src="https://images.prismic.io/kurmishadi/b223f690-65a9-42f9-ab64-c84d79418600_check-list.png?auto=compress,format=webp,height=60,width=60"
            alt="data evtry"
          />
          <!-- <span class="service_name">Data Entry</span> -->
          <li class="service_name"><a>Data Entry</a></li>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- -------------------------Services Section End----------------- -->

<!-- -------------------------Our Technologies Section Start----------------- -->

<section class="sscp40 resi_light_bg">
  <div class="container-fluid">
    <div class="section_heading">
      <h2>We Work On Technologies</h2>
      <p>We are committed to provide our customers with exceptional.</p>
    </div>

    <ngx-slick-carousel
      class="constant-simple-slider m-5"
      #slickModal="slick-carousel"
      [config]="slideConfig"
    >
      <div ngxSlickItem *ngFor="let slide of slides" class="slide">
        <img loading="lazy" src="{{ slide.img }}" alt="slide" width="100%" />
      </div>
    </ngx-slick-carousel>

    <div style="text-align: center">
      <app-consult-now-modal id="welcomedialog"></app-consult-now-modal>
    </div>
  </div>
</section>

<!-- -------------------------Our Technologies Section End----------------- -->

<!-- -------------------------Work Counter Section Start----------------- -->

<section class="sscp40 pb-0 counter_Parallax">
  <div class="container">
    <div class="row counter-box text-center" #myDOMElement>
      <div class="col-lg-3 col-md-3 col-sm-6 col-6 counter_item">
        <img
          loading="lazy"
          class="counter_icon"
          src="assets/images/served.webp"
          alt="served"
        />
        <p>
          <span class="counter">{{ conties }}</span
          ><span class="counter_plus">+</span>
        </p>
        <span class="counter-desc">Countries Served</span>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6 col-6 counter_item">
        <img
          loading="lazy"
          class="counter_icon"
          src="assets/images/projects.webp"
          alt="projects"
        />
        <p>
          <span class="counter">{{ pojectcomplete }}</span
          ><span class="counter_plus">+</span>
        </p>
        <span class="counter-desc">Project Completed</span>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6 col-6 counter_item">
        <img
          loading="lazy"
          class="counter_icon"
          src="assets/images/experts.webp"
          alt="experts"
        />
        <p>
          <span class="counter">{{ projectexpert }}</span
          ><span class="counter_plus">+</span>
        </p>
        <span class="counter-desc">Project Experts</span>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6 col-6 counter_item">
        <img
          loading="lazy"
          class="counter_icon"
          src="assets/images/groth.webp"
          alt="groth"
        />
        <p>
          <span class="counter">{{ marketing }}</span
          ><span class="counter_plus">+</span>
        </p>
        <span class="counter-desc">Market Experience</span>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-sm-6 pt-4">
        <div class="counter_slogan">
          <h3>
            <span>W</span>e’re <span>C</span>ommitted <br /><span>T</span>o
            <span>D</span>eliver <span>H</span>igh <span>Q</span>uality
            <span>P</span>rojects.
          </h3>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="trusted_section">
          <h4>We’re trusted by more than 100 clients</h4>
          <p>
            Our sole aim is to make you get recognition in the online market.
            Evolving your business to a newer height is our business. With our
            years of experience, we give the word of honour to provide you the
            best of the services by our savvy people.
          </p>
          <div class="Consult_btn">
            <app-consult-now-modal id="welcomedialog"></app-consult-now-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- -------------------------Work Counter Section Star----------------- -->

<!-- -------------------------Office Team Section Start----------------- -->

<section class="sscp40">
  <div class="container-fluid p-0">
    <div class="section_heading">
      <h2>Industries We Serve</h2>
      <p>
        We think big and have hands in all leading technology for Website design
        & Development.
      </p>
    </div>
    <div id="team-members" class="mt-5 container">
      <ngx-slick-carousel
        class="constant-simple-slider mt-4"
        #slickModal="slick-carousel"
        [config]="areasConfig"
      >
        <div
          ngxSlickItem
          *ngFor="let workingArea of workingAreas"
          class="slide"
        >
          <div class="crousel-slide">
            <div class="platform_item">
              <img
                loading="lazy"
                src="{{ workingArea.img }}"
                alt="working-area"
              />
              <div class="platform_name">
                {{ $any(workingArea).platform_name }}
              </div>
            </div>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</section>

<!-- -------------------------Office Team Section End----------------- -->

<!-- -------------------------Form Parallax Section Start----------------- -->

<section class="sscp40 pt-5 pb-5 Form_Parallax">
  <div class="container">
    <div class="slogan">
      <img
        loading="lazy"
        class="image-circle"
        src="assets/images/team-icon.webp"
        alt="team-icon"
      />
      <h3>
        Great Things In Business Are Never Done By One Person.
        <span>They’Re Done By A Team of People</span>
      </h3>
    </div>

    <div class="consolt_form_box mt-4">
      <h4 class="heading">Let's Get Your Project Started!</h4>
      <form [formGroup]="formData" class="row mt-3">
        <div class="col-sm-6 p-0">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              placeholder="Your Name"
              required
              formControlName="Name"
              (keypress)="noSpace($event)"
            />

            <div
              id="formError"
              *ngIf="fullName && fullName.invalid && fullName.touched"
            >
              <span *ngIf="fullName.errors?.['required']">
                <i
                  class="fa fa-exclamation-triangle"
                  style="color: #fe3061"
                  aria-hidden="true"
                ></i>
                Required
              </span>
              <span
                *ngIf="fullName.errors?.['pattern'] && !fullName.errors?.['required']"
              >
                <i
                  class="fa fa-exclamation-triangle"
                  style="color: #fe3061"
                  aria-hidden="true"
                ></i>
                Invalid Name input
              </span>
            </div>
          </div>
        </div>

        <div class="col-sm-6 p-0">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              placeholder="Email Address"
              required
              formControlName="Email"
            />
            <div id="formError" *ngIf="email && email.invalid && email.touched">
              <span *ngIf="email.errors?.['required']">
                <i
                  class="fa fa-exclamation-triangle"
                  style="color: #fe3061"
                  aria-hidden="true"
                ></i>
                Required
              </span>
              <span
                *ngIf="email.errors?.['pattern'] && !email.errors?.['required']"
              >
                <i
                  class="fa fa-exclamation-triangle"
                  style="color: #fe3061"
                  aria-hidden="true"
                ></i>
                Invalid email
              </span>
            </div>
          </div>
        </div>

        <div class="col-sm-6 p-0">
          <div class="form-group">
            <input
              type="text"
              (keypress)="numberOnly($event)"
              class="form-control"
              placeholder="Phone Number"
              required
              formControlName="Number"
              maxlength="10"
            />
            <div
              id="formError"
              *ngIf="contact && contact.invalid && contact.touched"
            >
              <span *ngIf="contact.errors?.['required']">
                <i
                  class="fa fa-exclamation-triangle"
                  style="color: #fe3061"
                  aria-hidden="true"
                ></i>
                Required
              </span>
              <span
                *ngIf="contact.errors?.['pattern'] && !contact.errors?.['required']"
              >
                <i
                  class="fa fa-exclamation-triangle"
                  style="color: #fe3061"
                  aria-hidden="true"
                ></i>
                Invalid input
              </span>
              <!-- <span *ngIf="(contact.errors?.['minlength'] || contact.errors?.['maxlength']) && !contact.errors?.['pattern']">
                <i class="fa fa-exclamation-triangle" style="color: #fe3061;" aria-hidden="true"></i> Phone Number Must
                be of 10 Digit </span> -->
            </div>
          </div>
        </div>

        <div class="col-sm-6 p-0">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              placeholder="Subject"
              required
              formControlName="Subject"
              (keypress)="noSpace($event)"
            />
            <div id="formError" *ngIf="sub && sub.invalid && sub.touched">
              <span *ngIf="sub.errors?.['required']">
                <i
                  class="fa fa-exclamation-triangle"
                  style="color: #fe3061"
                  aria-hidden="true"
                ></i>
                Required
              </span>
            </div>
          </div>
        </div>

        <div class="col-sm-12 p-0">
          <div class="form-group">
            <textarea
              name="message"
              class="form-control"
              placeholder="Write Message"
              required
              formControlName="msg"
              (keypress)="noSpace($event)"
            ></textarea>
            <div id="formError" *ngIf="msg && msg.invalid && msg.touched">
              <span *ngIf="msg.errors?.['required']">
                <i
                  class="fa fa-exclamation-triangle"
                  style="color: #fe3061"
                  aria-hidden="true"
                ></i>
                Required
              </span>
              <span
                *ngIf="msg.errors?.['minlength'] && !msg.errors?.['pattern']"
              >
                <i
                  class="fa fa-exclamation-triangle"
                  style="color: #fe3061"
                  aria-hidden="true"
                ></i>
                Minimum 10 Words are Required
              </span>
            </div>
          </div>
        </div>

        <div class="col-sm-12 mt-2">
          <button
            type="button"
            (click)="submit()"
            [disabled]="formData.invalid"
            class="form_button"
          >
            Send Message
          </button>
        </div>
      </form>
    </div>
  </div>
</section>

<!-- -------------------------Form Parallax Section End----------------- -->

<section class="mt-5 pt-5 mb-3">
  <div class="container">
    <div class="section_heading">
      <h2>Business Partners Around The World</h2>
    </div>
    <div id="countries">
      <ngx-slick-carousel
        class="constant-simple-slider"
        #slickModal="slick-carousel"
        [config]="contriesConfig"
      >
        <div
          ngxSlickItem
          *ngFor="let country of countries"
          class="slide mt-5 pt-3"
        >
          <div>
            <div class="member_item d-flex flex-column align-items-center">
              <div>
                <img
                  class="country-img"
                  loading="lazy"
                  src="{{ country.flag }}"
                  alt="country"
                />
              </div>
              <div class="name m-3 f_18 fw-bold">
                {{ country.name }}
              </div>
            </div>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</section>

<!-- -------------------------Office Team Section Start----------------- -->

<section class="sscp40">
  <div class="container-fluid p-0">
    <div class="section_heading">
      <h2>Meet The Expert Team</h2>
    </div>
    <div id="team-members" class="mt-5">
      <ngx-slick-carousel
        class="constant-simple-slider mt-4"
        #slickModal="slick-carousel"
        [config]="expertsConfig"
      >
        <div ngxSlickItem *ngFor="let expert of experts" class="slide">
          <div class="crousel-slide">
            <div class="member_item">
              <img loading="lazy" src="{{ expert.img }}" alt="expert" />
              <span class="name">{{ expert.tittle }}</span>
              <span class="position">{{ expert.position }}</span>
            </div>
            <!-- <div class="member_feedback">
              <p>A developer is an individual that builds and create software and applications. He or she writes, debugs and executes the source code of a software application. A developer is also known as a software developer,computer programmer, programmer, software coder or software engineer.</p>
            </div> -->
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</section>

<!-- -------------------------Office Team Section End----------------- -->

<!-- -------------------------Client Feedbacks Section Start----------------- -->

<section class="sscp40 feedback_sec">
  <div class="container">
    <div class="section_heading">
      <h2 class="text-light">
        Client Feedbacks
        <img
          loading="lazy"
          class="feedback_heart"
          src="assets/images/heart.webp"
          alt="heart"
        />
      </h2>
    </div>

    <div class="row mt-5">
      <div class="col-md-12">
        <div id="feedback">
          <div class="testimonial">
            <ngx-slick-carousel
              class="constant-simple-slider mt-4"
              #slickModal="slick-carousel"
              [config]="slideConfigmsg"
            >
              <div
                ngxSlickItem
                *ngFor="let feedback of clientFeedback"
                class="slide"
              >
                <div class="content">
                  <p class="description">{{ feedback.description }}</p>
                </div>
                <div class="testimonial-review">
                  <h3 class="testimonial-title">{{ feedback.title }}</h3>
                </div>
              </div>
            </ngx-slick-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- -------------------------Client Feedbacks Section End------------------->
<app-call-back></app-call-back>
<section class="mediaPR">
  <p class="media-PR pb-5">MEDIA & PR</p>
  <a
    href="https://www.digitaljournal.com/pr/resiliencesoft-is-celebrating-its-10-years-anniversary-of-being-a-leader-in-the-it-industry"
    target="_blank"
  >
    <div class="card p-4 ads-card" style="width: 18rem">
      <img
        loading="lazy"
        class="card-img-top"
        src="../../assets/images/digital_journal.webp"
        alt="digital journal"
      /></div></a
  ><br />
</section>
