import { Component, OnInit } from "@angular/core";
import { ThemePalette } from "@angular/material/core";
import { Title } from "@angular/platform-browser";
import { APIService } from "src/app/Services/main-service.service";
@Component({
    selector: 'loading-spinner',
    templateUrl: 'spinner.html',
  })
  export class LoadingSpinner implements OnInit{
    val = 0
    Active = false
    color: ThemePalette = 'accent';
    constructor (private api : APIService, private title : Title ){}
    ngOnInit (){
      this.title.setTitle('Uploading Your File')
        this.api.activeSpinner.subscribe(res => {
            this.Active = res
        })
        this.api.activeSpinnerVal.subscribe(res => {
            this.val = res
            if(this.val == 100){
                var marketingcount= setInterval(()=> {
                    var marketing = 0;
                    marketing++
                    if (marketing == 8) {
                      clearInterval(marketingcount);
                      this.Active = false
                    }
                  },100)
            }
        })
    }
}