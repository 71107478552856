import { Component, HostListener } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'office-new-website';
  active:any = '/'
  loading : boolean = true;

  constructor ( private  route : Router){
    this.route.events.subscribe( ( routerEvent : Event ) => {
      if( routerEvent instanceof NavigationStart )
      {
        this.loading = true
      }
      if( routerEvent instanceof NavigationEnd )
      {
        this.loading = false
      }
    })
  }


  isShow?: boolean;
  topPosToStartShowing = 100;

  @HostListener('window:scroll')
  checkScroll() {

    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  checkHomeRoute(url:any) {
    console.log(url);
    this.active = url
  }

  // TODO: Cross browsing
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}
