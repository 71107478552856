import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { APIService } from '../Services/main-service.service';
import { APIcalls } from '../Services/api';
import { Meta, Title } from '@angular/platform-browser';
import { description, keyword } from '../SEO_Constant/seo';
import { capatelize } from '../SEO_Constant/routes.constant';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  // for counter
  conties = 0;
  pojectcomplete = 0;
  projectexpert = 0;
  marketing = 0;
  // counter end
  isBrowser?: boolean;

  countries = [
    {
      flag: 'https://images.prismic.io/kurmishadi/ZkW2wiol0Zci9MIQ_australia.webp?auto=compress,format',
      name: 'Australia',
    },
    {
      flag: 'https://images.prismic.io/kurmishadi/ZkW2uSol0Zci9MIP_canada.webp?auto=compress,format',
      name: 'Canada',
    },
    {
      flag: 'https://images.prismic.io/kurmishadi/ZkW2wiol0Zci9MIQ_australia.webp?auto=compress,format',
      name: 'Germany',
    },
    {
      flag: 'https://images.prismic.io/kurmishadi/ZkW2-yol0Zci9MIg_USA.webp?auto=compress,format',
      name: 'United States of America',
    },
    {
      flag: 'https://images.prismic.io/kurmishadi/ZkW28yol0Zci9MIe_UK.webp?auto=compress,format',
      name: 'United Kingdom',
    },
    {
      flag: 'https://images.prismic.io/kurmishadi/ZkW27iol0Zci9MIb_UAE.webp?auto=compress,format',
      name: 'United Arab Emirates',
    },
    {
      flag: 'https://images.prismic.io/kurmishadi/ZkW26Sol0Zci9MIZ_switzerland.webp?auto=compress,format',
      name: 'Switzerland',
    },
    {
      flag: 'https://images.prismic.io/kurmishadi/ZkW24iol0Zci9MIY_southi-arebia.webp?auto=compress,format',
      name: 'Saudi Arabia',
    },
    {
      flag: 'https://images.prismic.io/kurmishadi/ZkW22Sol0Zci9MIW_south-africa.webp?auto=compress,format',
      name: 'South Africa',
    },
    {
      flag: 'https://images.prismic.io/kurmishadi/ZkW20iol0Zci9MIU_netherlands.webp?auto=compress,format',
      name: 'Netherlands',
    },
    {
      flag: 'https://images.prismic.io/kurmishadi/ZkW2ziol0Zci9MIT_Ireland.webp?auto=compress,format',
      name: 'Ireland',
    },
  ];
  locationData: any;

  contriesConfig = {
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  formData: FormGroup = new FormGroup({
    Name: new FormControl('', [
      Validators.required,
      Validators.pattern('[a-zA-Z- ]*'),
    ]),
    Email: new FormControl('', [
      Validators.required,
      Validators.pattern(
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      ),
    ]),
    Number: new FormControl('', [
      Validators.required,
      Validators.pattern('[0-9]*'),
      Validators.minLength(10),
      Validators.maxLength(10),
    ]),
    Subject: new FormControl('', [Validators.required]),
    msg: new FormControl('', [Validators.required, Validators.minLength(10)]),
  });

  noSpace(event: any) {
    if (event.keyCode === 32 && !event.target.value) return false;
    return true;
  }

  // form get
  get fullName() {
    return this.formData.get('Name');
  }
  get contact() {
    return this.formData.get('Number');
  }
  get email() {
    return this.formData.get('Email');
  }
  get sub() {
    return this.formData.get('Subject');
  }
  get msg() {
    return this.formData.get('msg');
  }

  // Carousel Content

  CarouselContent = [
    { caption: '', captionmain: '', url: '' },
    { caption: '', captionmain: '', url: '' },
  ];

  // feedback

  clientFeedback = [
    {
      description:
        'The flexibility and capacity of the resilience soft staff has been impressive. Provide best services and have a good customer handling power. This is the best factor.',
      title: 'Mr. Tarun Peswani',
    },
    {
      description:
        'They were very responsive team extremery easy to communicate and work with.The outcome was a flawless website, just the way I wanted.',
      title: 'Mr. Rohit',
    },
    {
      description:
        'The stability provided by resilience soft has help us scale up quickly and efficiently, we can always count on resilience soft. This is the best company ever for web development and designing also.',
      title: 'Meenu Dua',
    },
    {
      description:
        'A great company to work with! Our’s was the firm into mess before seeking their services. Now we are the market leader in terms of price and smooth web option as compare to before.',
      title: 'Aditya Tiwari',
    },
    {
      description:
        'Been a long journey of your support... you made us realise and understand our real market potential...kudos to resilience soft. very passionate about client success.',
      title: 'Mr. Afzal',
    },
    {
      description:
        'A progressive working atmosphere at Resilience Soft is what drove our company towards them. It was exactly what we thought. Happily enjoying client benifits with them.',
      title: 'Abdulla Bata',
    },
    {
      description:
        'The team at Resilience Soft is highly knowledgeable and the best part is that they are more than willing to share their knowledge and expertise. It really worked wonders for us, thanks for all the support.',
      title: 'Chakrdhar Soni',
    },
    {
      description:
        'Better team with sharp skill set, high quality on time delivery and commited bind service remain at the core of Resilience Soft....A true taskmaster and techno expert in its segment.',
      title: 'Mr. Sameer Chaohan',
    },
  ];

  // feedback end

  //FOR RESOURCES
  slides = [
    {
      img: 'https://images.prismic.io/kurmishadi/8da4c8d9-9f53-44b7-9974-2d13098de357_html_css.png?auto=compress,format=webp,height=83,width=206',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/10be0555-8e4d-49a4-9bbc-875a5a16c104_bootstrap.png?auto=compress,format=webp,height=83,width=206',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/9b43e8d6-6df1-4113-b349-6ef48622c307_php.png?auto=compress,format=webp,height=83,width=206',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/4f23bc11-c051-41fc-95ef-551d3949577b_dotnet.png?auto=compress,format=webp,height=83,width=206',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/9b09a745-ba28-41a6-84fe-6b5a6cde03a5_laravel.png?auto=compress,format=webp,height=83,width=206',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/933e2d4e-0298-47d8-bdd3-e367cf61c1ee_wordpress.png?auto=compress,format=webp,height=83,width=206',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/efee81f7-5ece-4852-a89b-72e6720adfa4_MySQL.png?auto=compress,format=webp,height=83,width=206',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/0c596dcc-d313-456b-bf65-73594e7bf3e9_jquery.png?auto=compress,format=webp,height=83,width=206',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/7c7e7164-87a4-4bcd-8571-933a104dc9b6_angular.png?auto=compress,format=webp,height=83,width=206',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/1a890e78-90d6-4931-ba19-7ae7de295216_react.png?auto=compress,format=webp,height=83,width=206',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/e7e59a7b-253c-46f1-bc52-7ea6330f4512_python.png?auto=compress,format=webp,height=83,width=206',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/688a74e8-5cdb-44eb-abac-ecd2442e1d38_node_.png?auto=compress,format=webp,height=83,width=206',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/33249da5-8428-4a89-923a-86344536afe0_mango_db.png?auto=compress,format=webp,height=83,width=206',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/ada6e991-5e1e-47bb-a47c-dc2d3e80a266_magento.png?auto=compress,format=webp,height=83,width=206',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/36c7259c-cd2b-419f-9148-99b6fe90c652_android.png?auto=compress,format=webp,height=83,width=206',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/a0694ea8-b2ae-401d-8e66-d1ca233ff6c4_ios-mac.png?auto=compress,format=webp,height=83,width=206',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/10937937-32dd-44b8-9226-434d12cdd4a2_ionic.png?auto=compress,format=webp,height=83,width=206',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/482010bd-30bd-4cd6-b4c2-e68ed9069196_ps.png?auto=compress,format=webp,height=83,width=206',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/20650fe5-265d-4d88-ae34-fc5a930b11ee_pI_copy.png?auto=compress,format=webp,height=83,width=206',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/91cf598c-1758-4bf3-a97c-224c6f608aac_figma.png?auto=compress,format=webp,height=83,width=206',
    },
  ];

  slideConfig = {
    slidesToShow: 6,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  // FOR CLIENTS
  slideConfigmsg = {
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1800,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  //end

  // FOR EXPERTS TEAM

  experts = [
    {
      img: 'https://images.prismic.io/kurmishadi/530015ce-677b-4bbb-84d4-d0dc7605bce9_pankaj.jpg?auto=compress,format=webp,height=297,width=252',
      tittle: 'Pankaj Mishra',
      position: 'Director & CEO',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/d81c324c-501f-4d0d-8d41-40ec4e795b5f_prateek.jpg?auto=compress,format=webp,height=297,width=252',
      tittle: 'Prateek Saluja',
      position: 'Director & CEO',
    },
    {
      img: '/assets/images/image/sushant-for-team.webp',
      tittle: 'Sushant Saluja',
      position: 'Functional Manager',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/65de2683-a34a-4e43-8804-a5fef77bdd56_deepak.jpg?auto=compress,format=webp,height=297,width=252',
      tittle: 'Deepak Verma',
      position: 'Manager',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/0fc62679-7b52-41c8-ae81-51d7ec8a01e7_irshad.jpg?auto=compress,format=webp,height=297,width=252',
      tittle: 'Irshad Alam',
      position: 'Web Designer',
    },
    // {
    //   img: 'https://images.prismic.io/kurmishadi/f9399423-6c8b-4843-8be2-8e2f2196184c_preeti.jpg?auto=compress,format=webp,height=297,width=252',
    //   tittle: 'Preeti Sahu',
    //   position: 'HR (Human resources)',
    // },
    {
      img: 'https://images.prismic.io/kurmishadi/45e2f9ca-80fb-4985-bc7b-86ab530e9db5_ankita.jpeg?auto=compress,format=webp,height=297,width=252',
      tittle: 'Ankita Choudhary',
      position: 'Web Developer (Angular)',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/63828c9d-5c63-4022-a098-2ab1bc80e410_shreya.jpg?auto=compress,format=webp,height=297,width=252',
      tittle: 'Shreya Gupta',
      position: 'Web Developer (PHP)',
    },
    {
      img: '/assets/images/image/manish.webp',
      tittle: 'Manish Chandra',
      position: 'Web & App Developer (React, React Native)',
    },
  ];

  expertsConfig = {
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1800,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // end

  // FOR OUR WORKING AREA

  workingAreas = [
    {
      img: 'https://images.prismic.io/kurmishadi/ad6219d6-62f4-4989-8139-1eb9e92924db_e-commerce.jpg?auto=compress,format=webp,height=283,width=283',
      member_feedback: '',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/a437696a-6db0-4f35-82e5-ecb76e7d60df_educations.jpg?auto=compress,format=webp,height=283,width=283',
      member_feedback: '',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/c091bb32-16bb-4acb-b47a-e580731f216f_grocery.jpg?auto=compress,format=webp,height=283,width=283',
      member_feedback: '',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/0a2bbb65-1071-4534-a17d-07f33d4072e0_transport.jpg?auto=compress,format=webp,height=283,width=283',
      member_feedback: '',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/a0330572-d8d8-4d9a-9ca7-8d0b4443bb9d_medical.jpg?auto=compress,format=webp,height=283,width=283',
      member_feedback: '',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/ac6483a8-b6f8-452a-befe-19f5862ada41_payments.jpg?auto=compress,format=webp,height=283,width=283',
      member_feedback: '',
    },
  ];

  areasConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1800,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  isadd?: boolean;

  // end
  constructor(
    private title: Title,
    private meta: Meta,
    public actRoute: ActivatedRoute,
    private api: APIService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      const contrycount = setInterval(() => {
        if (this.isadd) {
          this.conties++;
          if (this.conties == 20) {
            clearInterval(contrycount);
          }
        }
      }, 50);

      const projectcount = setInterval(() => {
        if (this.isadd) {
          this.pojectcomplete++;
          if (this.pojectcomplete == 200) {
            clearInterval(projectcount);
          }
        }
      }, 1);

      const projectexpertcount = setInterval(() => {
        if (this.isadd) {
          this.projectexpert++;
          if (this.projectexpert == 25) {
            clearInterval(projectexpertcount);
          }
        }
      }, 45);

      const marketingcount = setInterval(() => {
        if (this.isadd) {
          this.marketing++;
          if (this.marketing == 10) {
            clearInterval(marketingcount);
          }
        }
      }, 70);
    }
  }

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  ngOnInit(): void {
    this.title.setTitle(
      'Top Website Development Company In India | Resiliencesoft'
    );
    this.meta.removeTag('name=description');
    this.meta.removeTag('name=keywords');
    this.meta.addTags([
      {
        name: 'description',
        content: description.Home,
      },
      {
        name: 'keywords',
        content: capatelize(keyword.globle) + capatelize(keyword.Home),
      },
    ]);
    // this.api.getData(APIcalls.getApi.Employee).subscribe((res) => {res});
  }

  @ViewChild('myDOMElement', { static: true }) MyDOMElement?: ElementRef;

  @HostListener('window:scroll', ['$event'])
  isScrolledIntoView() {
    if (this.MyDOMElement) {
      const rect = this.MyDOMElement.nativeElement.getBoundingClientRect();
      const topShown = rect.top < window.innerHeight;
      const bottomShown = rect.bottom >= 60;
      this.isadd = topShown && bottomShown;
      // console.log(rect, topShown, bottomShown, this.isadd)
    }
    if (this.isadd) {
    }
  }

  submit() {
    const formData = new FormData();
    formData.append('name', this.formData.get('Name')?.value);
    formData.append('email', this.formData.get('Email')?.value);
    formData.append('phone', this.formData.get('Number')?.value);
    formData.append('subject', this.formData.get('Subject')?.value);
    formData.append('message', this.formData.get('msg')?.value);
    this.formData.reset();
    this.api.postData(APIcalls.mailApi.Contact, formData).subscribe({
      next: (res: any) => {
        this.api.openSnackBar(
          ' Form Has Been Successfully Submitted, We Will Contact You Soon ',
          5
        );
      },
      error: () => {
        this.api.openSnackBar(
          " We Can't Proceed Your Request at This Time ",
          2
        );
      },
    });
  }
}
