<nav class="navbar backdrop" style="transform: translatez(10px);" id="primary_nav_wrap">
  <div class="mobile-header w-100">
    <div class="contact bg-1">
        <p><a href="tel:9109911372" style="color: white;"><img loading="lazy" src="assets/images/call-1.webp" alt="call">9109911372</a></p>
    </div>
    <div class="contact bg-2">
        <p routerLink="/contact-us"> <img src="/assets/images/email-1.webp" alt="">Contact Us</p>
    </div>
  </div>
    <div class="navbar-container container nav_container">
        <input type="checkbox" name="" [(ngModel)]="hidemenu">
        <div class="hamburger-lines">
            <span class="line line1"></span>
            <span class="line line2"></span>
            <span class="line line3"></span>
        </div>
        <ul class="menu-items">
            <div class="listLogo">
            <!-- <a _ngcontent-uoh-c115="" routerlink=""  style="transform: translateY(-8px);" ng-reflect-router-link="" href="/"> -->
                <img loading="lazy" _ngcontent-uoh-c115="" routerlink="" class="listLogos" src="/assets/images/logo.webp" alt="Resilience soft logo" tabindex="0" ng-reflect-router-link="">
            <!-- </a> -->
            </div>
            <li><a routerLink="/" [class.activenavbtn]="checkHomeRoute()">Home</a></li>
            <li><a routerLink="/about-us" routerLinkActive="activenavbtn">About</a></li>
            <li><a class="dropdowncontainer" [ngClass]="{'showdropdownnow' : first}" (click)="changemenu1()">Services
                    <img loading="lazy" class="down_arrow" src="assets/images/down-arrow.webp" alt="Down-arrow"></a>
                <ul class="menu_dropdown">
                    <div class="container d-block">
                        <div class="submenu">
                            <ul>
                                <h6>Web solution</h6>
                                <li><a routerLink="service/website-design" routerLinkActive="activenavbtn"> Website
                                        Designing</a>
                                </li>
                                <li><a routerLink="service/web-development" routerLinkActive="activenavbtn"> Web
                                        Development</a></li>
                                <li><a routerLink="service/web-application-development" routerLinkActive="activenavbtn">
                                        Web
                                        Application Development</a></li>
                                <li><a routerLink="service/web-hosting-solutions" routerLinkActive="activenavbtn"> Web
                                        Hosting
                                        Solutions</a></li>
                            </ul>
                        </div>
                        <div class="submenu">
                            <ul>
                                <h6>App solution</h6>
                                <li><a routerLink="service/android-app-development" routerLinkActive="activenavbtn">
                                        Android App
                                        Development</a></li>
                                <li><a routerLink="service/ios-app-development" routerLinkActive="activenavbtn"> Ios App
                                        Development</a></li>
                                <li><a routerLink="service/hybrid-app-development" routerLinkActive="activenavbtn">
                                        Hybrid App
                                        Development</a></li>
                                <li><a routerLink="service/native-app-development" routerLinkActive="activenavbtn">
                                        Native App
                                        Development</a></li>
                            </ul>
                        </div>
                        <div class="submenu">
                            <ul>
                                <h6>Digital Marketing</h6>
                                <li><a routerLink="service/search-engine-optimisation" routerLinkActive="activenavbtn">
                                        Search
                                        Engine Optimisation</a></li>
                                <li><a routerLink="service/digital-media-marketing" routerLinkActive="activenavbtn">
                                        Digital Media
                                        Marketing</a></li>
                                <li><a routerLink="service/social-media-promotion" routerLinkActive="activenavbtn"> Social
                                        Media
                                        Promotion</a></li>
                                <li><a routerLink="service/bulk-sms-and-email-marketing" routerLinkActive="activenavbtn">
                                        Bulk Sms &
                                        Email Marketing</a></li>
                            </ul>
                        </div>
                        <div class="submenu">
                            <ul class="pb-5rem">
                                <h6>More Services</h6>
                                <li><a routerLink="service/logo-design" routerLinkActive="activenavbtn"> Logo Design</a>
                                </li>
                                <li><a routerLink="service/graphic-design" routerLinkActive="activenavbtn"> Graphics
                                        Design</a>
                                </li>
                                <li><a routerLink="service/digital-visiting-card" routerLinkActive="activenavbtn"> Digital
                                        Visiting Card</a></li>
                                <li><a routerLink="service/video-creation" routerLinkActive="activenavbtn"> Video
                                        Creation</a>
                                </li>
                                <!-- <li><a routerLink="bulkSms"> Bulk SMS</a></li> -->
                            </ul>
                        </div>
                    </div>
                </ul>
            </li>
            <li><a routerLink="/ourproducts" routerLinkActive="activenavbtn">Our products</a></li>
            <li><a routerLink="/careers" routerLinkActive="activenavbtn">Careers</a></li>
            <li><a routerLink="/contact-us" routerLinkActive="activenavbtn">Contact us</a></li>
            <li><a class="dropdowncontainer" [ngClass]="{'showdropdownnow' : isvisible}" (click)="changemenu2()">More
                    <img loading="lazy" class="down_arrow" src="/assets/images/down-arrow.png" alt="Down-arrow"></a>
                <ul class="menu_dropdown">
                    <div class="container d-block">
                        <div class="submenu more_links">
                            <ul>
                                <li><a routerLink="/payment" routerLinkActive="activenavbtn"> Payment Partners</a></li>
                                <!-- <li><a routerLink="/portfolio"> Portfolio</a></li> -->
                                <li><a routerLink="/gallery" routerLinkActive="activenavbtn"> Gallery</a></li>
                                <li><a routerLink="/meettheteam" routerLinkActive="activenavbtn"> Meet The Team</a></li>
                                <li><a routerLink="/internship" routerLinkActive="activenavbtn"> Internship</a></li>
                                <li><a routerLink="/training" routerLinkActive="activenavbtn"> Training</a></li>
                                <!-- <li><a routerLink="/contractual-service" routerLinkActive="activenavbtn"> Contractual Services</a></li> -->
                            </ul>
                        </div>
                    </div>
                </ul>
            </li>
            <li>
                <div class="nav_Consult">
                    <a href="/assets/Resiliencesoft.pdf" target="blank" style="padding: 8px 20px;"> Play Book </a>
                </div>
            </li>
        </ul>
        <a class="menu-hamburger" routerLink="" class="logo">
            <img loading="lazy" routerLink="" src="/assets/images/logo.webp" alt="Resilience soft logo">
        </a>
    </div>
</nav>

<div style="height:72px"></div>
