<app-header></app-header>
<router-outlet></router-outlet>
<app-floating-whatsapp></app-floating-whatsapp>
<app-footer></app-footer>
<button *ngIf="isShow" class=" backtop" (click)="gotoTop()"><i class="fa fa-arrow-up"></i></button>

<!--            Loading             -->

<div class="loader-wrapper" *ngIf="loading">
    <div class="loader-box">
      <div class="icon">
        <img loading="lazy" src="assets/images/loader.png" alt="scroll-up">
      </div>
    </div>
  </div>

  <div class="mobile-footer">
    <div class="mobile-footer-menu text-center">
        <a routerLink="/"   >
            <p class="m-0 text-uppercase" [class.activenavbtn]="active === '/'" (click)="checkHomeRoute('/')" > <i class="fa fa-home"></i><span>Home</span></p>
        </a>
    </div>
    <div class="mobile-footer-menu">
        <a routerLink="/careers">
            <p class="m-0 text-uppercase" [class.activenavbtn]="active === 'careers'" (click)="checkHomeRoute('careers')" > <i class="fa fa-graduation-cap" aria-hidden="true"></i><span>Career</span></p>
        </a>
    </div>
    <div class="mobile-footer-menu">
        <a routerLink="/ourproducts">
            <p class="m-0 text-uppercase" [class.activenavbtn]="active === 'ourproducts'" (click)="checkHomeRoute('ourproducts')"  ><i class="fa fa-shopping-bag" aria-hidden="true"></i><span>Product</span></p>
        </a>
    </div>
    <div class="mobile-footer-menu">
        <a href="https://maps.google.com/maps/dir//Resiliencesoft+2nd+Floor+Emerald+Plaza,+Opposite+Cg+Plaza+Near+Agrasen+Chowk+Telephone+Exchange+Rd+Bilaspur,+Chhattisgarh+495001/@22.0767519,82.1529364,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x3a280b3994383cad:0x96b4892d81b97229">
            <p class="m-0 text-uppercase" [class.activenavbtn]="active === 'map'" (click)="checkHomeRoute('map')"><i class="fa fa-map-marker" aria-hidden="true"></i>Directions</p>
        </a>
    </div>
</div>
