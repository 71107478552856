 <section class="meet-team">
  <div class="container">
    <div class="row">
      <ng-container *ngFor="let team of meetTheTeam; index as i">

        <div class="team-member_item img">
          <img [src]="team.img" alt="pankaj" [ngClass]="{'boxShadow-1': i % 5 === 0,'boxShadow-2': i % 5 === 1,'boxShadow-3': i % 5 === 2,'boxShadow-4': i % 5 === 3,'boxShadow-5': i % 5 === 4}"/>
          <h4 [ngClass]="{'color-1': i % 5 === 0,'color-2': i % 5 === 1,'color-3': i % 5 === 2,'color-4': i % 5 === 3,'color-5': i % 5 === 4}">{{team.name}}</h4>
          <p>{{team.designation}}</p>
        </div>
      </ng-container>
    </div>
  </div>
</section>
<!-- <section class="meet-team">
  <div class="container">
    <div class="row">
      <div class="team-member_item img">
        <img src="../../assets/images/image/pankaj.svg" alt="pankaj" style="box-shadow: 0px 10px 8px 1px #2196f3" />
        <h4 style="color: #ff2f8b">Pankaj Mishra</h4>
        <p>Director &amp; CEO</p>
      </div>
      <div class="team-member_item img">
        <img
          src="../../assets/images//image/prateek.svg"
          alt="prateek"
          style="box-shadow: 0px 10px 8px 1px #4caf50"
        />
        <h4 style="color: #4caf50">Prateek Saluja</h4>
        <p>Director &amp; CEO</p>
      </div>
      <div class="team-member_item img">
        <img
          src="../../assets/images//image/sushant-saluja.webp"
          alt="sushant"
          style="box-shadow: 0px 10px 8px 1px #2196f3"
        />
        <h4 style="color: #2196f3">Sushant Saluja</h4>
        <p>Functional Manager</p>
      </div>
      <div class="team-member_item img">
        <img
          src="../../assets/images//image/deepak.svg"
          alt="deepak"
          style="box-shadow: 0px 10px 8px 1px #ff5722"
        />
        <h4 style="color: #ff5722">Deepak Verma</h4>
        <p>Functional Manager</p>
      </div>
      <div class="team-member_item img">
        <img
          src="../../assets/images//image/irshad.svg"
          alt="irshad"
          style="box-shadow: 0px 10px 8px 1px #ac12c6"
        />
        <h4 style="color: #ac12c6">Irshad Alam</h4>
        <p>Senior Web Designer</p>
      </div>
      <div class="team-member_item img">
        <img
          src="../../assets/images/image/preeti.svg"
          alt="preeti"
          style="box-shadow: 0px 10px 8px 1px #ff2f8b"
        />
        <h4 style="color: #ff2f8b">Preeti Sahu</h4>
        <p>HR (Human Resources)</p>
      </div>
      <div class="team-member_item img">
        <img
          src="../../assets/images/image/sshreya.svg"
          alt="shreya"
          style="box-shadow: 0px 10px 8px 1px #4caf50"
        />
        <h4 style="color: #4caf50">Shreya Gupta</h4>
        <p>Senior PHP Developer</p>
      </div>
      <div class="team-member_item img">
        <img
          src="https://images.prismic.io/kurmishadi/a9c3375c-8043-4424-842b-33d7622befcb_manish-chandra.webp?auto=compress,format=webp,height=200,width=200"
          alt="Manish"
          style="box-shadow: 0px 10px 8px 1px #2196f3"
        />
        <h4 style="color: #2196f3">Manish Chandra</h4>
        <p>Senior React Native Developer</p>
      </div>
      <div class="team-member_item img">
        <img
          src="../../assets/images/image/ankita.svg"
          alt="ankita"
          style="box-shadow: 0px 10px 8px 1px #ff5722"
        />
        <h4 style="color: #ff5722">Ankita Choudhary</h4>
        <p>Senior Angular Developer</p>
      </div>
      <div class="team-member_item img">
        <img
          src="../../assets/images/image/arzoo.svg"
          alt="arzoo"
          style="box-shadow: 0px 10px 8px 1px #ac12c6"
        />
        <h4 style="color: #ac12c6">Arzoo Khan</h4>
        <p>Senior PHP Developer</p>
      </div>
      <div class="team-member_item img">
        <img
          src="../../assets/images/image/seema.svg"
          alt="seema"
          style="box-shadow: 0px 10px 8px 1px #ff2f8b"
        />
        <h4 style="color: #ff2f8b">Seema Sahu</h4>
        <p>QA</p>
      </div>
      <div class="team-member_item img">
        <img
          src="https://images.prismic.io/kurmishadi/e0a5af85-2f15-4633-9d21-27e571f82d77_Ritka1.webp?auto=compress,format=webp,height=200,width=200"
          alt="ritika"
          style="box-shadow: 0px 10px 8px 1px #4caf50"
        />
        <h4 style="color: #4caf50">Ritka Sharma</h4>
        <p>Angular Developer</p>
      </div>
      <div class="team-member_item img">
        <img
          src="https://images.prismic.io/kurmishadi/37e6f9b7-3dac-459e-ae3a-019d264cc6a9_sanya.webp?auto=compress,format=webp,height=200,width=200"
          alt="sanya"
          style="box-shadow: 0px 10px 8px 1px #2196f3"
        />
        <h4 style="color: #2196f3">Sanya Mishra</h4>
        <p>React Native Developer</p>
      </div>
      <div class="team-member_item img">
        <img
          src="https://images.prismic.io/kurmishadi/9dc52190-2eea-4bb4-8906-76c5566aaada_Animesh-jaiswal.webp?auto=compress,format=webp,height=200,width=200"
          alt="animesh"
          style="box-shadow: 0px 10px 8px 1px #ff5722"
        />
        <h4 style="color: #ff5722">Animesh Jaisawal</h4>
        <p>QA Manual Tester</p>
      </div>
      <div class="team-member_item img">
        <img
          src="https://images.prismic.io/kurmishadi/d5d58b2d-5336-4a95-8445-c8b81bbb734d_Utpal-Tiwari+.webp?auto=compress,format=webp,height=200,width=200"
          alt="utpal"
          style="box-shadow: 0px 10px 8px 1px #ac12c6"
        />
        <h4 style="color: #ac12c6">Utpal Tiwari</h4>
        <p>PHP Developer</p>
      </div>
      <div class="team-member_item img">
        <img
          src="../../assets/images/image/stephie.webp"
          alt="Stephie"
          style="box-shadow: 0px 10px 8px 1px #ff2f8b"
        />
        <h4 style="color: #ff2f8b">Stephie Susan Wilson</h4>
        <p>SMM (Social Media Manager)</p>
      </div>
      <div class="team-member_item img">
        <img
          src="https://images.prismic.io/kurmishadi/567ccb2b-bcf4-492c-87f4-43e17ae0c6b2_meera-dhruw.webp?auto=compress,format=webp,height=200,width=200"
          alt="meera"
          style="box-shadow: 0px 10px 8px 1px #4caf50"
        />
        <h4 style="color: #4caf50">Meera Dhruv</h4>
        <p>Front office executive</p>
      </div>
      <div class="team-member_item img">
        <img
          src="../../assets/images/image/parmeshvar.webp"
          alt="parmeshwar"
          style="box-shadow: 0px 10px 8px 1px #2196f3"
        />
        <h4 style="color: #2196f3">Parmeshwar Sahu</h4>
        <p>Web Designer</p>
      </div>
      <div class="team-member_item img">
        <img
          src="../../assets/images/image/mithlesh.webp"
          alt="mithlesh"
          style="box-shadow: 0px 10px 8px 1px #ff5722"
        />
        <h4 style="color: #ff5722">Mithlesh Das</h4>
        <p>React Native Developer</p>
      </div>
      <div class="team-member_item img">
        <img
          src="../../assets/images/image/jaspreet.webp"
          alt="jaspreet"
          style="box-shadow: 0px 10px 8px 1px #ac12c6"
        />
        <h4 style="color: #ac12c6">Jaspreet Kaur</h4>
        <p>HR (Human Resources)</p>
      </div>
      <div class="team-member_item img">
        <img
          src="../../assets/images/image/pramod.webp"
          alt="pramod"
          style="box-shadow: 0px 10px 8px 1px #ff2f8b"
        />
        <h4 style="color: #ff2f8b">Pramod Singh</h4>
        <p>Angular Developer</p>
      </div>
      <div class="team-member_item img">
        <img
          src="../../assets/images/image/vikrant.webp"
          alt="vikrant"
          style="box-shadow: 0px 10px 8px 1px #4caf50"
        />
        <h4 style="color: #4caf50">Vikrant Sangekar</h4>
        <p>Senior .Net Developer</p>
      </div>
      <div class="team-member_item img">
        <img
          src="../../assets/images/image/sonal.webp"
          alt="Sonal"
          style="box-shadow: 0px 10px 8px 1px #2196f3"
        />
        <h4 style="color: #2196f3">Sonal Pasait</h4>
        <p>.Net Developer</p>
      </div>
      <div class="team-member_item img">
        <img
          src="../../assets/images/image/santosh.webp"
          alt="Santosh"
          style="box-shadow: 0px 10px 8px 1px #ff5722"
        />
        <h4 style="color: #ff5722">Santosh Kumar Anand</h4>
        <p>.Net Developer</p>
      </div>
      <div class="team-member_item img">
        <img
          src="../../assets/images/image/niraj.webp"
          alt="jaspreet"
          style="box-shadow: 0px 10px 8px 1px #ac12c6"
        />
        <h4 style="color: #ac12c6">Niraj Shaw</h4>
        <p>QA</p>
      </div>
      <div class="team-member_item img">
        <img
          src="../../assets/images/image/shruti.webp"
          alt="Stephie"
          style="box-shadow: 0px 10px 8px 1px #ff2f8b"
        />
        <h4 style="color: #ff2f8b">Shruti Das</h4>
        <p>HR (Human Resources)</p>
      </div>
      <div class="team-member_item img">
        <img
          src="../../assets/images/image/sonika.webp"
          alt="vikrant"
          style="box-shadow: 0px 10px 8px 1px #4caf50"
        />
        <h4 style="color: #4caf50">Sonika Nirala</h4>
        <p>React Native Developer</p>
      </div>
      <div class="team-member_item img">
        <img
          src="../../assets/images/image/devender.webp"
          alt="Sonal"
          style="box-shadow: 0px 10px 8px 1px #2196f3"
        />
        <h4 style="color: #2196f3">Devender Sharma</h4>
        <p>Senior .Net Developer</p>
      </div>
      <div class="team-member_item img">
        <img
          src="../../assets/images/image/sangram.webp"
          alt="Santosh"
          style="box-shadow: 0px 10px 8px 1px #ff5722"
        />
        <h4 style="color: #ff5722">Sangram Panda</h4>
        <p>.Net Developer</p>
      </div>
      <div class="team-member_item img">
        <img
          src="../../assets/images/image/yash.jpg"
          alt="jaspreet"
          style="box-shadow: 0px 10px 8px 1px #ac12c6"
        />
        <h4 style="color: #ac12c6">Yash Patidar</h4>
        <p>React Native Developer</p>
      </div>
      <div class="team-member_item img">
        <img
          src="../../assets/images/image/vaibhav.webp"
          alt="Stephie"
          style="box-shadow: 0px 10px 8px 1px #ff2f8b"
        />
        <h4 style="color: #ff2f8b">Vaibhav Dubay</h4>
        <p>Angular Developer</p>
      </div>
      <div class="team-member_item img">
        <img
          src="../../assets/images/image/hemadri.webp"
          alt="vikrant"
          style="box-shadow: 0px 10px 8px 1px #4caf50"
        />
        <h4 style="color: #4caf50">Hemadri Sahu</h4>
        <p>Angular Developer</p>
      </div>
    </div>
  </div>
</section> -->
