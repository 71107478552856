import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogContentContactBTNDialog } from '../page/contact-btn';

@Component({
  selector: 'app-contact-btn-modal',
  templateUrl: './contact-btn.component.html',
  styleUrls: ['../contact-btn.component.css']
})
export class ContactBtnModalComponent implements OnInit{
  @Input() id?: string;
  @Input() post?:string;
  ButtonName = " Apply Now "
  constructor(public dialog: MatDialog) {
  }
  ngOnInit(): void {
    if (this.id == "applyjob"){
      this.ButtonName = " Apply job ";
    }
    // if(!localStorage.getItem('opened')){
    //   const dialogRef = this.dialog.open(DialogContentContactBTNDialog);
    //   dialogRef.afterClosed().subscribe(result => {
    //     console.log(`Dialog result: ${result}`);
    //   });
    //   localStorage.setItem('Dialogopen','welcomedialog')
    //   localStorage.setItem ('opened','true')
    // }
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogContentContactBTNDialog);
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
    localStorage.setItem('post',this.post as string)
  }

  onSpace(event:any){
    console.log(event)
    if(event.keyCode===32 && !event.target.value) return false;
    return true;
    }
}
