   <iframe
        style="
          margin: 0;
          padding: 0;
          border: 0;
          background: none;
          max-width: none;
          max-height: none;
          width: 500px;
          height: 500px;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          overflow-y: auto;
          overflow-x: hidden;
        "
        frameborder="0"
        scrolling="yes"
        src="https://www.trustedsite.com/verify-modal?js=1&amp;host=resiliencesoft.com"
      ></iframe>
      <div
        id="trustedsite-tm-close"
        style="
          max-width: none;
          max-height: none;
          background-image: url('https://cdn.ywxi.net/static/img/modal-close.png');
          background-size: 11px 11px;
          background-repeat: no-repeat;
          background-position: center center;
          z-index: 1000005;
          position: absolute;
          width: 11px;
          height: 11px;
          right: 10px;
          top: -20px;
          cursor: zoom-out;
        "
      >
        <div></div>
      </div>