<footer class="footer_bg footer_design">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <a href="https://www.resiliencesoft.com" class="footer_logo"
          ><img loading="lazy" src="assets/images/footer-logo.webp" alt="resiliencesoft"
        /></a>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-sm-2">
        <div class="footer_link_colom">
          <h1 class="heading">Important Links</h1>
          <ul>
            <li>
              <h1 class="h1_link"><a routerLink="/">Home</a></h1>
            </li>
            <li>
              <h1 class="h1_link"><a routerLink="/about-us">About us</a></h1>
            </li>
            <li>
              <h1 class="h1_link">
                <a routerLink="/software-Development">Services</a>
              </h1>
            </li>
            <li>
              <h1 class="h1_link">
                <a routerLink="/contact-us">Contact us</a>
              </h1>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-sm-3 pr-0">
        <div class="footer_link_colom">
          <h1 class="heading">Web Solution</h1>
          <ul>
            <li>
              <h1 class="h1_link">
                <a routerLink="service/website-Design">Website Designing</a>
              </h1>
            </li>
            <li>
              <h1 class="h1_link">
                <a routerLink="service/software-Development"
                  >Software Development</a
                >
              </h1>
            </li>
            <li>
              <h1 class="h1_link">
                <a routerLink="service/Web-Application-Development"
                  >Web Application Development</a
                >
              </h1>
            </li>
            <li>
              <h1 class="h1_link">
                <a routerLink="service/web-Hosting-Solutions"
                  >Web Hosting Solutions</a
                >
              </h1>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-sm-2 pl-0">
        <div class="footer_link_colom">
          <h1 class="heading">App Solution</h1>
          <ul>
            <li>
              <h1 class="h1_link">
                <a routerLink="service/android-app-development"
                  >Android App Development</a
                >
              </h1>
            </li>
            <li>
              <h1 class="h1_link">
                <a routerLink="service/ios-app-development"
                  >Ios App Development</a
                >
              </h1>
            </li>
            <li>
              <h1 class="h1_link">
                <a routerLink="service/hybrid-app-development"
                  >Hybrid App Development</a
                >
              </h1>
            </li>
            <li>
              <h1 class="h1_link">
                <a routerLink="service/native-app-development"
                  >Native App Development</a
                >
              </h1>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-sm-2">
        <div class="footer_link_colom">
          <h1 class="heading">Digital Marketing</h1>
          <ul>
            <li>
              <h1 class="h1_link">
                <a routerLink="service/search-Engine-Optimisation"
                  >Search Engine Optimisation</a
                >
              </h1>
            </li>
            <li>
              <h1 class="h1_link">
                <a routerLink="service/digital-Media-Marketing"
                  >Digital Media Marketing</a
                >
              </h1>
            </li>
            <li>
              <h1 class="h1_link">
                <a routerLink="service/social-Media-Promotion"
                  >Social Media Promotion</a
                >
              </h1>
            </li>
            <li>
              <h1 class="h1_link">
                <a routerLink="service/bulk-Sms-and-Email-Marketing"
                  >Bulk Sms & Email Marketing</a
                >
              </h1>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-sm-3">
        <div class="footer_link_colom">
          <h1 class="heading">Connect with Resiliencesoft</h1>
          <ul>
            <li class="pb-2">
              <h1 class="h1_link">
                <a
                  class="footer_adrs pl-4"
                  routerLink="/contact-us"
                  fragment="maplocation"
                  >2nd Floor Emerald Plaza, Telephone Exchange Road, Opposite CG
                  Plaza, Bilaspur (C.G) Pin 495001
                </a>
              </h1>
            </li>
            <li class="pb-2">
              <h1 class="h1_link">
                <a
                  class="footer_mail pl-4"
                  href="mailto:info@resiliencesoft.com"
                  >info&#64;resiliencesoft.com</a
                >
              </h1>
            </li>
            <li class="pb-2">
              <h1 class="h1_link">
                <a class="footer_call pl-4" href="tel:9109911372">9109911372</a>
                <span class="comma">,</span><a href="tel:9201996377">9201996377</a>
                <span class="comma">,</span><a href="tel:9201996378">9201996378</a>
              </h1>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="footer_bottom">
          <div class="row">
            <div class="col-sm-8">
              <div class="row">
                <div class="col-sm-6">
                  <p class="copyright">
                    Copyright © <span>{{currentYear}}</span> ResilienceSoft. All Rights Reserved
                  </p>
                </div>
                <div class="col-sm-6 pl-0 footer_terms_privacy">
                  <!-- <a href="#">Terms and Conditions</a> -->
                  <!-- <a href="#">Terms and Conditions</a> -->
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <ul class="social_links float-right">
                <li>
                  <a
                    href="https://www.facebook.com/OfficialResiliencesoft"
                    target="_blank"
                    ><i class="fab fa-facebook"></i
                  ></a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/resiliencesoftofficial"
                    target="_blank"
                    ><i class="fab fa-instagram"></i
                  ></a>
                </li>
                <li>
                  <a href="https://twitter.com/resiliencesoft" target="_blank"
                    ><i class="fab fa-twitter"></i
                  ></a>
                </li>
                <li>
                  <a
                    href="https://in.pinterest.com/resiliencesoft/"
                    target="_blank"
                    ><i class="fab fa-pinterest"></i
                  ></a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCLCk552hkub1znblDNwqcBw"
                    target="_blank"
                    ><i class="fab fa-youtube"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div></div>
  </div>
  <app-trust-btn-modal></app-trust-btn-modal>
</footer>
