<!-- ------------------Page Heading Start------------- -->

<div class="page_heading">
  <div class="container">
    <h2>Careers</h2>
  </div>
</div>

<!-- ------------------Page Heading End------------- -->

<!-- -------------------------Job Section Start----------------- -->

<section id="jobs">
  <div class="container">
    <div class="abt_job_heading mb-3">
      <h2>Come, join us! <span>We’re hiring.</span></h2>
      <p>We believe that each one of us should be able to find our dream job, and we constantly strive hard to make that
        possible. Apply now!</p>
    </div>

     <!-- php devloper -->
    <div class="job_item">
      <div>
        <h3 class="job_name">PHP Developer</h3>
      </div>
      <div class="job_action mt-2">
        <div class="experience item">1 to 2 year Experience</div>
        <div class="worktime item">Full Time</div>
        <div class="job_location item">Bilaspur</div>
      </div>
      <p class="job_description">A PHP developer is responsible for writing server-side web application logic. PHP developers usually develop back-end components, connect the application with the other (often third-party) web services, and support the front-end developers by integrating their work with the application.</p>
      <div class="mt-3"><app-contact-btn-modal [post]="'PHP Developer'" id="applynow"></app-contact-btn-modal>
    </div>
    </div>
     <!-- End php devloper -->

      <!-- .Net Developer -->
    <div class="job_item">
      <div>
        <h3 class="job_name">.Net Developer</h3>
      </div>
      <div class="job_action mt-2">
        <div class="experience item">1 to 2 year Experience</div>
        <div class="worktime item">Full Time</div>
        <div class="job_location item">Bilaspur</div>
      </div>
      <p class="job_description">.NET developer is a software engineer who builds the software using Microsoft's . NET technologies. He is responsible for the design, implementation and development of the software products according to technical needs.</p>
      <div class="mt-3"><app-contact-btn-modal [post]="'.Net Developer'" id="applynow"></app-contact-btn-modal>
      </div>
    </div>
     <!-- End .Net Developer -->

      <!-- .Net core Developer -->
    <div class="job_item">
      <div>
        <h3 class="job_name">.Net Core Developer</h3>
      </div>
      <div class="job_action mt-2">
        <div class="experience item">2 to 3 year Experience</div>
        <div class="worktime item">Full Time</div>
        <div class="job_location item">Bilaspur</div>
      </div>
      <p class="job_description">NET Core Developer is an expert who can design and develop modern web applications all stacks like UI, Back-end, database, version control, server and APIs.</p>
      <div class="mt-3"><app-contact-btn-modal [post]="'.Net Core Developer'" id="applynow"></app-contact-btn-modal>
      </div>
    </div>
     <!-- End .Net Developer -->

      <!-- Ionic Developer  -->
    <div class="job_item">
      <div>
        <h3 class="job_name">Ionic Developer</h3>
      </div>
      <div class="job_action mt-2">
        <div class="experience item">1 to 2 year Experience</div>
        <div class="worktime item">Full Time</div>
        <div class="job_location item">Bilaspur</div>
      </div>
      <p class="job_description">Ionic is the app platform for web developers. Build amazing mobile, web, and desktop apps all with one shared code base and open web standards.</p>
      <div class="mt-3"><app-contact-btn-modal [post]="'Ionic Developer'" id="applynow"></app-contact-btn-modal>
      </div>
    </div>
     <!-- End Ionic Developer -->

      <!-- React Native Developer -->
    <div class="job_item">
      <div>
        <h3 class="job_name">React Native Developer</h3>
      </div>
      <div class="job_action mt-2">
        <div class="experience item">1 to 2 year Experience</div>
        <div class="worktime item">Full Time</div>
        <div class="job_location item">Bilaspur</div>
      </div>
      <p class="job_description">React components wrap existing native code and interact with native APIs via React's declarative UI paradigm and JavaScript. This enables native app development for whole new teams of developers, and can let existing native teams work much faster.</p>
      <div class="mt-3"><app-contact-btn-modal [post]="'React Native Developer'" id="applynow"></app-contact-btn-modal>
      </div>
    </div>
     <!-- End React Native Developer -->

     <!-- React Native Developer -->
    <div class="job_item">
      <div>
        <h3 class="job_name">React js Developer</h3>
      </div>
      <div class="job_action mt-2">
        <div class="experience item">1 to 2 year Experience</div>
        <div class="worktime item">Full Time</div>
        <div class="job_location item">Bilaspur</div>
      </div>
      <p class="job_description">React js developers design and implement user interface components for JavaScript-based web and mobile applications using the React open-source library ecosystem. These skilled front-end developers are involved in all stages of interface component design, from conception through to final testing.</p>
      <div class="mt-3"><app-contact-btn-modal [post]="'React JS Developer'" id="applynow"></app-contact-btn-modal>
      </div>
    </div>
     <!-- End React Native Developer -->

      <!-- Angular Developer -->
    <div class="job_item">
      <div>
        <h3 class="job_name">Angular Developer</h3>
      </div>
      <div class="job_action mt-2">
        <div class="experience item">1 to 2 year Experience</div>
        <div class="worktime item">Full Time</div>
        <div class="job_location item">Bilaspur</div>
      </div>
      <p class="job_description">Angular developers are talented JavaScript developers who possess knowledge of theoretic concepts of software engineering. Angular developers work on the client side of the software, which is the core activity of the developer. They can build a full-fledged User Interface service for websites and web applications.</p>
      <div class="mt-3"><app-contact-btn-modal [post]="'Angular Developer'" id="applynow"></app-contact-btn-modal>
      </div>
    </div>
     <!--End Angular Developer -->

     <!-- Manual & Automation Tester-->
    <div class="job_item">
      <div>
        <h3 class="job_name">Manual & Automation Tester</h3>
      </div>
      <div class="job_action mt-2">
        <div class="experience item">1 to 2 year Experience</div>
        <div class="worktime item">Full Time</div>
        <div class="job_location item">Bilaspur</div>
      </div>
      <p class="job_description">Manual testing is the process of identifying bugs and defects in software without the help of software testing automation tools. In this procedure, QA manual testers execute test cases manually while considering the end user's perspective.</p>
      <div class="mt-3"><app-contact-btn-modal [post]="'Manual & Automation Tester'" id="applynow"></app-contact-btn-modal>
      </div>
    </div>
   <!-- End Manual & Automation Tester-->

    <!-- web designer -->
    <div class="job_item">
      <div>
        <h3 class="job_name">Web Designer</h3>
      </div>
      <div class="job_action mt-2">
        <div class="experience item">1 to 2 year Experience</div>
        <div class="worktime item">Full Time</div>
        <div class="job_location item">Bilaspur</div>
      </div>
      <p class="job_description">Designing visual imagery for websites and ensuring that they are in line with branding
        for clients. Working with different content management systems. Communicating design ideas using user flows,
        process flows, site maps, and wire frames.</p>
        <div class="mt-3"><app-contact-btn-modal [post]="'Web-Designer'" id="applynow"></app-contact-btn-modal></div>
    </div>
    <!-- End web designer -->

    <!-- Intership/Trainee -->

    <div class="job_item">
      <div>
        <h3 class="job_name">Internship/Trainee</h3>
      </div>
      <div class="job_action mt-2">
        <div class="experience item">Fresher</div>
        <div class="worktime item">Full Time</div>
        <div class="job_location item">Bilaspur</div>
      </div>
      <p class="job_description">Those who are looking for career opportunity / those who want to build there career in IT sector.</p>
        <div class="mt-3"><app-contact-btn-modal [post]="'Intership/Trainee'" id="applynow"></app-contact-btn-modal></div>
    </div>

    <!-- End Intership/Trainee -->

  </div>
</section>

<!-- -------------------------Job Section End----------------- -->


<div id="popup1" class="modal fade">
  <div class="popup">
    <button type="button"  class="close" data-dismiss="modal">&times;</button>
         <div class="row  p-0">
      <div class="col-sm-6">
   <img loading="lazy" src="/assets/images/job-apply.jpg" alt="job">
 </div>
   <div class="col-sm-6 ">
    <div class="sidebar">
        <div class="block clearfix">
            <h3 class="ab_sec_heading-pop mb-4"><b>Submit Your Details</b></h3>
            <form class="my_detail" method="post" enctype="multipart/form-data">
              <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label for="email">Full Name</label>
                        <input type="email" class="form-control-job user_icon" id="name" (keypress)="onSpace($event)"/>
                    </div>
                </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                  <div class="form-group">
                      <label for="email">Email</label>
                      <input type="email" class="form-control-job mail_icon" id="mail" />
                  </div>
              </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
                <div class="form-group">
                    <label for="email">Phone Number</label>
                    <input type="text" class="form-control-job user_icon" id="Number" />
                </div>
            </div>
        </div>
        <div _ngcontent-wor-c20="" class="row">
          <div _ngcontent-wor-c20="" class="col-sm-12">
            <div _ngcontent-wor-c20="" class="form-group">
              <label _ngcontent-wor-c20="" for="email">Upload Resume (*.doc,*docx,*.pdf)</label>
              <input _ngcontent-wor-c20="" type="file" id="file_1" name="file_1" required="required" class="form-control-job user_icon">
            </div>
          </div>
        </div>
                 <div class="alert alert-success" id="success" style="display: none;"></div>
            <div class="alert alert-danger" id="failed" style="display: none;"></div>
                <input type="submit" value="Submit" class="submit-button btn btn-default">
            </form>
        </div>
    </div>
  </div>
  </div>
</div>
</div>
