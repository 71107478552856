<!-- ------------------Page Heading Start------------- -->

<div class="page_heading">
    <div class="container">
        <h2>Contact Us</h2>
    </div>
</div>

<!-- ------------------Page Heading End------------- -->
<section>
    <div class="contact-section pdt-110 pdb-95 pdb-lg-90">
        <div class="container">


            <div class="row">
                <div class="col-lg-5 col-xl-5">
                    <h5 class="sub-title-side-line text-primary-color mrt-0 mrb-15">Get In Touch</h5>
                    <h2 class="faq-title mrb-30">Have Any Questions?</h2>
                    <ul class="social-list list-lg list-primary-color list-flat mrb-md-60 clearfix">
                        <li><a href="https://www.facebook.com/OfficialResiliencesoft/" target="_blank"><i class="fab fa-facebook"></i></a></li>
                        <li><a href="https://twitter.com/ResilienceSoft1/" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="https://www.instagram.com/resiliencesoftofficial/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        <li><a href="https://www.linkedin.com/in/hr-resiliencesoft-640933224" target="_blank"><i class="fab fa-linkedin"></i></a></li>
                    </ul>
                </div>
                <div class="col-lg-7 col-xl-7">
                    <div class="contact-form">
                        <form [formGroup]="formData">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group mrb-25">
                                        <input name="ctl00$ContentPlaceHolder1$txtenqnam" type="text"
                                            id="ctl00_ContentPlaceHolder1_txtenqnam" placeholder="Full Name*"
                                            class="form-control" required formControlName="Name" (keypress)="noSpace($event)">
                                            @if(fullName && fullName.invalid && fullName.touched){
                                                <div id="formError">
                                                    <span *ngIf="fullName.errors?.['required']">
                                                        <i class="fa fa-exclamation-triangle" style="color: #fe3061;" aria-hidden="true"></i> Required
                                                    </span>
                                                    <span *ngIf="fullName.errors?.['pattern'] && !fullName.errors?.['required']">
                                                        Invalid Name input </span>
                                                </div>
                                            }
                                        
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group mrb-25">
                                        <input name="ctl00$ContentPlaceHolder1$txtenqeml" type="email"
                                            id="ctl00_ContentPlaceHolder1_txtenqeml" placeholder="Email*"
                                            class="form-control" required formControlName="Email">
                                            @if(email && email.invalid && email.touched){
                                                <div id="formError">
                                                    <span *ngIf="email.errors?.['required']">
                                                        <i class="fa fa-exclamation-triangle" style="color: #fe3061;" aria-hidden="true"></i>Required
                                                    </span>
                                                    <span *ngIf="email.errors?.['pattern'] && !email.errors?.['required']">
                                                        <i class="fa fa-exclamation-triangle" style="color: #fe3061;" aria-hidden="true"></i> Invalid email </span>
                                            </div>
                                            }
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group mrb-25 position-relative">
                                        <!-- <input name="ctl00$ContentPlaceHolder1$txtenqmob" type="text"
                                            id="ctl00_ContentPlaceHolder1_txtenqmob" placeholder="Mobile Number*"
                                            class="form-control" required formControlName="Number"> -->
                                            <!-- <div id="formError" *ngIf="contact && contact.invalid && contact.touched">
                                              <span *ngIf="contact.errors?.['required']">
                                                  <i class="fa fa-exclamation-triangle" style="color: #fe3061;" aria-hidden="true"></i> Required
                                              </span>
                                              <span *ngIf="contact.errors?.['pattern'] && !contact.errors?.['required']">
                                                  <i class="fa fa-exclamation-triangle" style="color: #fe3061;" aria-hidden="true"></i> Invalid input </span>
                                              <span *ngIf="(contact.errors?.['minlength'] || contact.errors?.['maxlength']) && !contact.errors?.['pattern']">
                                                  <i class="fa fa-exclamation-triangle" style="color: #fe3061;" aria-hidden="true"></i> Phone Number Must be of 10 Digit </span>
                                      </div> -->

                                        <ngx-mat-intl-tel-input
                                        name="ctl00$ContentPlaceHolder1$txtenqmob"
                                        id="ctl00_ContentPlaceHolder1_txtenqmob"
                                        class="ngx-floating"
                                        formControlName="Number"
                                        [format]="'national'"
                                        [enablePlaceholder]="true"
                                        [enableSearch]="true"
                                        name="phone"
                                        [preferredCountries]="['in', 'us']"
                                        #phone
                                      >
                                      </ngx-mat-intl-tel-input>
                                      <div id="formError" *ngIf="contact && contact.invalid && contact.touched">
                                        <span *ngIf="contact.errors?.['required']">
                                            <i class="fa fa-exclamation-triangle" style="color: #fe3061;" aria-hidden="true"></i> Required
                                        </span>
                                        <span *ngIf="contact.errors?.['validatePhoneNumber'] && !contact.errors?.['required']">
                                            <i class="fa fa-exclamation-triangle" style="color: #fe3061;" aria-hidden="true"></i> Invalid input </span>
                                        <!-- <span *ngIf="(contact.errors?.['minlength'] || contact.errors?.['maxlength']) && !contact.errors?.['pattern']">
                                            <i class="fa fa-exclamation-triangle" style="color: #fe3061;" aria-hidden="true"></i> Phone Number Must be of 10 Digit </span> -->
                                </div>

                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group mrb-25">
                                        <textarea name="ctl00$ContentPlaceHolder1$txtmsg" rows="4" cols="20"
                                            id="ctl00_ContentPlaceHolder1_txtmsg" class="form-text"
                                            placeholder="Message*" formControlName="msg" (keypress)="noSpace($event)"></textarea>
                                            <div id="formError" *ngIf="msg && msg.invalid && msg.touched">
                                                <span *ngIf="msg.errors?.['required']">
                                                    <i class="fa fa-exclamation-triangle" style="color: #fe3061;" aria-hidden="true"></i> Required
                                                </span>
                                                <span *ngIf="msg.errors?.['minlength'] && !msg.errors?.['pattern']">
                                                    <i class="fa fa-exclamation-triangle" style="color: #fe3061;" aria-hidden="true"></i> Minimum 10 Words are Required </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-8">
                                    <div class="form-group">

                                        <button class="form_button_send" (click)="submit(phone)" type="button"  [disabled]="formData.invalid"
                                            name="submit">Send
                                                Message
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="row mrb-40">
                <div class="col-lg-6 col-xl-4">
                    <div class="contact-block d-flex mrb-30">


                        <div class="contact-icon-container">
                            <a>
                                <img loading="lazy" class="contact-icon" src="/assets/images/office-icon.png" alt="office-icon">
                            </a>
                        </div>


                        <div class="contact-details mrl-30">
                            <h5 class="icon-box-title mrb-10">Our Address</h5>
                            <p class="mrb-0"><a>2nd Floor Emerald Plaza, Opposite Vinayak Netralaya,
                                    Near Agrasen Chowk,
                                    Link Road Bilaspur C.G.</a> </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-4">
                    <div class="contact-block d-flex mrb-30">

                        <div class="contact-icon-container">
                            <a href="tel:07752-414580">
                                <img loading="lazy" class="contact-icon" src="/assets/images/telephone1.png" alt="telephone">
                            </a>
                        </div>

                        <div class="contact-details mrl-30">
                            <h5 class="icon-box-title mrb-10">Phone Number</h5>
                            <p class="mrb-0"><a class="contact-number-office" href="tel:9109911372">9109911372</a>
                               &nbsp;, <a class="contact-number-office" href="tel:9201996377">9201996377</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-4">
                    <div class="contact-block d-flex mrb-30">

                        <div class="contact-icon-container">
                            <a href="mailto:info@resiliencesoft.com">
                                <img loading="lazy" class="contact-icon" src="/assets/images/mail1.png" alt="mail">
                            </a>
                        </div>

                        <div class="contact-details mrl-30">
                            <h5 class="icon-box-title mrb-10">Email Us</h5>
                            <p class="mrb-0"><a class="contact-number-office" href="mailto:info@resiliencesoft.com"> info&#64;resiliencesoft.com </a>
                                <br>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section>
  <div #view class="elfsight-app-4992bdf1-a43a-49b3-8e6d-47edd405d5df" ></div>
</section> -->

<section>
    <div id="maplocation">
        <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3697.2949616509404!2d82.15240278966186!3d22.07654963837098!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a280b3994383cad%3A0x96b4892d81b97229!2sResiliencesoft!5e0!3m2!1sen!2sin!4v1663847088436!5m2!1sen!2sin"
            width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
</section>
