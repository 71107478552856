import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { APIcalls } from '../Services/api';
import { APIService } from '../Services/main-service.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css'],
})
export class GalleryComponent implements OnInit {
  data?: any;
  officedata = new Array();
  activitydata = new Array();
  employeedata = new Array();
  tourdata = new Array();
  partydata = new Array();
  isBrowser: boolean;

  constructor(private title: Title, private api: APIService, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if(this.isBrowser){
      this.title.setTitle('Gallery | Resiliencesoft');
      this.api.getData(APIcalls.getApi.Gallery).subscribe((res: any) => {
        console.log(res)
        this.data = res.data as any;
        for (let key in this.data) {
          if (this.data[key].img_category === 'Office') {
            this.officedata.push(this.data[key]);
          }
        }
  
        for (let key in this.data) {
          if (this.data[key].img_category === 'Activity') {
            this.activitydata.push(this.data[key]);
          }
        }
  
        for (let key in this.data) {
          if (this.data[key].img_category === 'Employee') {
            this.employeedata.push(this.data[key]);
          }
        }
  
        for (let key in this.data) {
          if (this.data[key].img_category === 'Tour') {
            this.tourdata.push(this.data[key]);
          }
        }
  
        for (let key in this.data) {
          if (this.data[key].img_category === 'Party') {
            this.partydata.push(this.data[key]);
          }
        }
      });
    }
  }
}
