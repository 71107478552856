<!-- ------------------Page Heading Start------------- -->

<div class="page_heading">
    <div class="container">
        <h2>Payment Partners</h2>
    </div>
</div>

<!-- ------------------Page Heading End------------- -->

<!-- -------------------------Payment Section Start----------------- -->

<section class="payment_holder">
    <div class="container">
        <div class="payment_card">
            <form>
                <div class="payment_option row">
                    <div class="payment_item col">
                        <input id="radio-1" [ngClass]="{ active: this.activeBar === 'stripe' }"
                            (click)="changeTab('stripe');" class="radio-custom" name="radio-group" type="radio" />
                        <label for="radio-1" class="radio-custom-label"><img loading="lazy" src="assets/images/stripe.png" alt="strip" /></label>
                    </div>

                    <div class="payment_item col">
                        <input id="radio-2" [ngClass]="{ active: this.activeBar === 'razorpay' }"
                            (click)="changeTab('razorpay');" class="radio-custom" name="radio-group" type="radio" />
                        <label for="radio-2" class="radio-custom-label"><img loading="lazy" src="assets/images/razorpay.png" alt="razorpay" /></label>
                    </div>
                    <!-- <div class="payment_item">
                        <input id="radio-3" [ngClass]="{ active: this.activeBar === 'instamojo' }"
                            (click)="changeTab('instamojo');" class="radio-custom" name="radio-group" type="radio" />
                        <label for="radio-3" class="radio-custom-label"><img loading="lazy"
                                src="assets/images/instamojo.png" /></label>
                    </div> -->
                </div>

                <!-- -------------------------ByDefaultform-------------------------------- -->
                <div class="tab-pane" *ngIf="this.activeBar === 'bydefoult'">
                    <div class="payment_fields">
                    <div class="bydefoult_payment">
                        <img loading="lazy" src="assets/images/payment-hold.png" alt="payment-hold">
                        <p class="payment_option_txt">Select Payment Option</p>
                    </div>

                    </div>
                </div>
              <!-- -------------------------EndByDefaultform-------------------------------- -->

              <!-- ------------------------------razorpayForm--------------------------------------->

                <div [formGroup]="razorpayform" class="tab-pane" *ngIf="this.activeBar === 'razorpay'">
                    <div class="payment_form mb-3">
                        <div class="payment_logo"><img loading="lazy" src="assets/images/razorpay.png" alt="razorpay"/></div>
                    </div>


                    <div class="payment_fields">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="email">Full Name</label>
                                    <input type="text" formControlName="name" class="form-control user_icon" id="name" />
                                    <div id="formError" *ngIf="Rname && Rname.invalid && Rname.touched">
                                        <span *ngIf="Rname.errors?.['required']">
                                            <i _ngcontent-serverapp-c3020481885="" aria-hidden="true" class="fa fa-exclamation-triangle" style="color: #fe3061;"></i>Required
                                        </span>
                                        <span *ngIf="Rname.errors?.['pattern'] && !Rname.errors?.['required']">
                                            <i _ngcontent-serverapp-c3020481885="" aria-hidden="true" class="fa fa-exclamation-triangle" style="color: #fe3061;"></i>Invalid Name input </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="email">Email</label>
                                    <input type="email" formControlName="mail" class="form-control mail_icon" id="email" />
                                    <div id="formError" *ngIf="Rmail && Rmail.invalid && Rmail.touched ">
                                        <span *ngIf="Rmail.errors?.['required']">
                                            <i _ngcontent-serverapp-c3020481885="" aria-hidden="true" class="fa fa-exclamation-triangle" style="color: #fe3061;"></i> Required
                                        </span>
                                        <span *ngIf="Rmail.errors?.['pattern'] && !Rmail.errors?.['required']">
                                            <i _ngcontent-serverapp-c3020481885="" aria-hidden="true" class="fa fa-exclamation-triangle" style="color: #fe3061;"></i>Invalid Email Id </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="num">phone</label>
                                    <input type="tel" formControlName="phone" class="form-control user_icon" id="email" (keypress)="preventNonNumeric($event)"/>
                                    <div id="formError" *ngIf="Rphone && Rphone.invalid && Rphone.touched ">
                                        <span *ngIf="Rphone.errors?.['required']">
                                            <i _ngcontent-serverapp-c3020481885="" aria-hidden="true" class="fa fa-exclamation-triangle" style="color: #fe3061;"></i>Required
                                        </span>
                                        <span *ngIf="razorpayform.get('phone')?.errors?.['minlength']">
                                            <i aria-hidden="true" class="fa fa-exclamation-triangle" style="color: #fe3061;"></i> Enter 10 digit number
                                          </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="Currency">Currency</label>
                                    <select formControlName="currency">
                                        <option value="INR">INR</option>
                                        <option value="USD">USD</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="email">Amount</label>
                                    <input type="tel" formControlName="price" class="form-control cash_icon" placeholder="0.00" (keypress)="preventNonNumeric($event)"/>
                                    <div id="formError" *ngIf="Rprice && Rprice.invalid && Rprice.touched ">
                                        <span *ngIf="Rprice.errors?.['required']">
                                            <i _ngcontent-serverapp-c3020481885="" aria-hidden="true" class="fa fa-exclamation-triangle" style="color: #fe3061;"></i>Required
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12">
                                <button class="pay" (click)="pay()">Pay with Razorpay</button>
                            </div>
                        </div>

                    </div>
                </div>
<!-- ------------------------------EndrazorpayForm--------------------------------------->

<!-- ------------------------------InstamojoForm--------------------------------------->
                <!-- <div class="tab-pane" *ngIf="this.activeBar === 'instamojo'">
                    <div class="payment_form mb-3">
                        <div class="payment_logo"><img loading="lazy" src="assets/images/instamojo.png" /></div>
                    </div>

                    <div class="payment_fields">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="email">Full Name</label>
                                    <input type="text" class="form-control user_icon" id="name" />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="email">Email</label>
                                    <input type="email" class="form-control mail_icon" id="email" />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="email">Payment Purpose</label>
                                    <input type="text" class="form-control" id="email" />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="email">Currency</label>
                                    <select>
                                        <option>--</option>
                                        <option>USD</option>
                                        <option>INR</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="email">Amount</label>
                                    <input type="email" class="form-control cash_icon" placeholder="0.00" />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12">
                                <button class="pay">Pay with Instamojo</button>
                            </div>
                        </div>
                    </div>
                </div> -->

                <!-- ------------------------------EndInstamojoForm--------------------------------------->

                <!-- ------------------------------stripeForm--------------------------------------->
                <div [formGroup]="stripeform" class="tab-pane" *ngIf="this.activeBar === 'stripe'">
                    <div class="payment_form mb-3">
                        <div class="payment_logo"><img loading="lazy" src="assets/images/stripe.png" alt="stripe"/></div>
                    </div>

                    <div class="payment_fields"  *ngIf="!Stripeisvalid">

                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="text">Cardholder Name</label>
                                    <input type="text" formControlName="name" class="form-control user_icon" >
                                    <div id="formError" *ngIf="Sname && Sname.invalid && Sname.touched ">
                                        <span *ngIf="Sname.errors?.['required']">
                                            <i _ngcontent-serverapp-c3020481885="" aria-hidden="true" class="fa fa-exclamation-triangle" style="color: #fe3061;"></i>Required
                                        </span>
                                        <span *ngIf="Sname.errors?.['pattern'] && !Sname.errors?.['required']">
                                            <i _ngcontent-serverapp-c3020481885="" aria-hidden="true" class="fa fa-exclamation-triangle" style="color: #fe3061;"></i>Invalid Name input </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="email">Email</label>
                                    <input type="email" formControlName="mail" class="form-control mail_icon" id="email" />
                                    <div id="formError" *ngIf="Smail && Smail.invalid && Smail.touched ">
                                        <span *ngIf="Smail.errors?.['required']">
                                            <i _ngcontent-serverapp-c3020481885="" aria-hidden="true" class="fa fa-exclamation-triangle" style="color: #fe3061;"></i> Required
                                        </span>
                                        <span *ngIf="Smail.errors?.['pattern'] && !Smail.errors?.['required']">
                                            <i _ngcontent-serverapp-c3020481885="" aria-hidden="true" class="fa fa-exclamation-triangle" style="color: #fe3061;"></i>Invalid Email Id </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="Currency">Currency</label>
                                    <select formControlName="currency">
                                        <option value="inr">INR</option>
                                        <option value="usd">USD</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="email">Amount</label>
                                    <input type="tel" formControlName="price" class="form-control cash_icon" id="amount"
                                        placeholder="0.00" (keypress)="preventNonNumeric($event)"/>
                                        <div id="formError" *ngIf="Sprice && Sprice.invalid && Sprice.touched ">
                                            <span *ngIf="Sprice.errors?.['required']">
                                                <i _ngcontent-serverapp-c3020481885="" aria-hidden="true" class="fa fa-exclamation-triangle" style="color: #fe3061;"></i>Required
                                            </span>
                                        </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12">
                                <!-- [disabled]="stripeform.invalid" -->
                                <button class="pay" (click)="initializePayment()">Proceed to pay</button>
                            </div>
                        </div>

                    </div>
                    <div class="stripe-pay" *ngIf="Stripeisvalid">
                        <form id="payment-form">
                            <div id="payment-element" #paymentelement>
                            <!--Stripe.js injects the Payment Element-->
                            </div>
                            <button type="button" [disabled]="submitdisabled" (click)="handleSubmit($event)" id="submit">
                                <span id="button-text" [class.hidden]="!spinner">Pay now</span>
                            </button>
                            <div id="payment-message" class="hidden"></div>
                        </form>
                    </div>
                </div>

                <!-- ------------------------------EndstripeForm--------------------------------------->
            </form>
        </div>
    </div>
</section>

<!-- -------------------------Payment Section End----------------- -->


<section *ngIf="iswaiting" id="spinnerforpayment">
    <div class="spinnercontainer">
        <div></div>
    </div>
</section>
