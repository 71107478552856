import { HttpClient, HttpEventType, HttpResponse } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { APIcalls } from "src/app/Services/api";
import { APIService } from "src/app/Services/main-service.service";
import { environment } from "src/environments/environment";
import { Router} from '@angular/router';
import { MatDialog } from "@angular/material/dialog";
@Component({
    selector: 'dialog-content-example-dialog',
    templateUrl: 'contact-btn.html',
    styleUrls: ['../contact-btn.component.css']
  })
  export class DialogContentContactBTNDialog implements OnInit{
    @Input() id = '';
    src = '/assets/images/job-apply.jpg'
    fileToUpload: File | null = null;
    Data :any;
    formData: FormGroup;
    BaseURL = environment?.BaseUrl
    post?: string;
    percentCompleted?: number;
    isunning?: boolean;
    allowedfiles = [ 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword']
    fileallow = false;


    constructor(

      private api : APIService,
      private fb: FormBuilder,
      private http : HttpClient,
      private router:Router,
      public dialog: MatDialog
    ) {

    this.formData = this.fb.group(
      {
        Name : ['', [Validators.required,Validators.pattern('[a-zA-Z- ]*')]],
        Email : ['', [Validators.required, Validators.pattern(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)]],
        Number : ['', [Validators.required,Validators.pattern('[0-9]*'), Validators.minLength(10),Validators.maxLength(10)]],
        Resume : ['', [Validators.required]],
      }
    )

  }

  ngOnInit(): void {
    this.id = localStorage.getItem('Dialogopen') as string;
    this.post = localStorage.getItem('post') as string;
    if ( this.id == 'applynow'){
      this.src = '/assets/images/job-apply.jpg'
    }
  }

  onSpace(event:any){
    if(event.keyCode===32 && !event.target.value) return false;
    return true;
    }

  get fullName(){
    return this.formData.get('Name')
  }
  get contact(){
    return this.formData.get('Number')
  }
  get email(){
    return this.formData.get('Email')
  }
  get resume(){
    return this.formData.get('Resume')
  }

  handleFileInput(files: any) {
    this.fileallow = false
    this.Data = files[0]
    this.allowedfiles.forEach(file => {
      if(this.Data.type == file)
      {
        this.fileallow = true
      }
    });
    if(!this.fileallow)
    {
      this.resume?.reset()
      this.resume?.markAsTouched()
      this.resume?.updateValueAndValidity()
      this.resume?.setErrors({format: true})
    }
  }

  isSubmitting = false;
  async onSubmit() {
    if (this.isSubmitting) {
      return; // If already submitting, return to prevent multiple submissions
    }
    this.isSubmitting = true;
    const formData = new FormData();
    formData.append('Name', this.formData.get('Name')?.value)
    formData.append('email', this.formData.get('Email')?.value)
    formData.append('post', this.post as string)
    formData.append('Number', this.formData.get('Number')?.value)
    formData.append('file_1', this.Data, this.Data.name)
    this.http.post( this.BaseURL + APIcalls.mailApi.Career , formData , { reportProgress : true, observe : 'events'})
    .subscribe((e: any)=>{
      this.isunning = true;
      this.api.changeSpinnerValue( 0 )
      this.api.changeActiveSpinner(true);
      if (e.type === HttpEventType.UploadProgress) {
        this.api.changeActiveSpinner(true);
        var percentCompleted = Math.round(100 * e.loaded  / e.total);
        this.api.changeSpinnerValue( percentCompleted )
      } else if (e instanceof HttpResponse) {
        var isUploaded = true;
        this.api.changeActiveSpinner(!isUploaded);
        this.isunning= false
        if(e.ok){
          this.api.openSnackBar(" Form Has Been Successfully Submitted, We Will Contact You Soon ",5)
          this.dialog.closeAll()
        }
        else {
          this.api.openSnackBar(" We Can't Proceed Your Request at This Time ",2)
        }
        this.isSubmitting = false;
      }})
    }
  }
