<!-- ------------------Page Heading Start------------- -->

<div class="page_heading">
    <div class="container">
        <h2>Gallery</h2>
    </div>
</div>

<!-- ------------------Page Heading End------------- -->
<!-- -------------------------Gallery Section Start----------------- -->


<section class="portfolio_section">
    <div class="container">
        <!-- <div class="row"> -->
            <mat-tab-group >
                <mat-tab label="All">
                    <app-gallerymodel [GalleryData]=data [showCount]="true"></app-gallerymodel>
                </mat-tab>
                <mat-tab label="Office">
                    <app-gallerymodel [GalleryData]=officedata [showCount]="true"></app-gallerymodel>
                </mat-tab>
                <mat-tab label="Activity">
                    <app-gallerymodel [GalleryData]=activitydata [showCount]="true"></app-gallerymodel>
                </mat-tab>
                <mat-tab label="Employee">
                    <app-gallerymodel [GalleryData]=employeedata [showCount]="true"></app-gallerymodel>
                </mat-tab>
                <mat-tab label="Tour">
                    <app-gallerymodel [GalleryData]=tourdata [showCount]="true"></app-gallerymodel>
                </mat-tab>
                <mat-tab label="Party">
                    <app-gallerymodel [GalleryData]=partydata [showCount]="true"></app-gallerymodel>
                </mat-tab>
            </mat-tab-group>
        <!-- </div> -->
    </div>
</section>


<!-- -------------------------Portfolio Section End----------------- -->