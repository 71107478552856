
    <div class="trusted_site"
    id="trustedsite-tm-image"
    title="TrustedSite Certified"
    tabindex="0"
    style="
      margin: 0px;
      padding: 0px;
      border: 0px;
      background: url('https://cdn.ywxi.net/meter/resiliencesoft.com/205.svg?ts=1626454159622&amp;l=en-US')
        center center / 92px 38px no-repeat rgb(255, 255, 255);
      max-width: none;
      max-height: none;
      position: fixed;
      height: 38px !important;
      width: 92px !important;
      overflow: hidden !important;
      bottom: -13px;
      right: 0px !important;
      z-index: 1000003 !important;
      cursor: pointer !important;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 5px 0px;
      border-top-left-radius: 2px;
      transition: all 0.2s ease 0s;
    "
    (click)="openDialog()"
  >
  <div></div>
</div>
