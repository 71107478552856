import { Component } from '@angular/core';

@Component({
  selector: 'app-contractual-service',
  templateUrl: './contractual-service.component.html',
  styleUrl: './contractual-service.component.scss'
})
export class ContractualServiceComponent {
  currentIndex :number;

  carouselConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    arrows: true,
    dots: false,
    infinite: true,
    speed: 300,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false
        }
      }
    ]
  };

  slides=[
    {
      "imgSrc": "../../assets/img/clock.png",
      "title": "Hourly Rates",
      "description": "Many developers charge an hourly rate for their services. The rate can vary widely depending on factors such as the developer's experience, expertise, and geographic location. More experienced developers typically command higher hourly rates."
    },
    {
      "imgSrc": "../../assets/img/best-price.png",
      "title": "Fixed-Price Contracts",
      "description": "In some cases, developers may opt for fixed-price contracts, where they agree on a set fee for completing a specific scope of work. Profit margins on fixed-price contracts can vary depending on the developer's ability to accurately estimate project costs and manage expenses."
    },
    {
      "imgSrc": "../../assets/img/save-money.png",
      "title": "Cost of Doing Business",
      "description": "Developers must consider their overhead costs, including equipment, software licenses, office space, utilities, and other expenses associated with running their business. These costs factor into determining the profitability of each project."
    },
    {
      "imgSrc": "../../assets/img/connection.png",
      "title": "Client Relationships and Referrals",
      "description": "Building strong relationships with clients and delivering exceptional results can lead to repeat business and referrals, which can increase a developer's profitability over time."
    },
    {
      "imgSrc": "../../assets/img/competitive.png",
      "title": "Market Demand and Competition",
      "description": "Developers operating in high-demand niches or with specialized skills may be able to command higher rates and achieve higher profit margins compared to those in more competitive or saturated markets."
    },
    {
      "imgSrc": "../../assets/img/deep-learning.png",
      "title": "Continuous Learning and Skill Development",
      "description": "Investing in ongoing learning and skill development can enhance a developer's expertise and value proposition, allowing them to command higher rates and increase their profitability."
    }
  ]


  onAfterChange(event: any) {
    this.currentIndex = event.currentSlide;
  }

  isCenterSlide(index: number): boolean {
  const isLastSlide = this.currentIndex === this.slides.length - 1;
  const isFirstSlide = index === 0;
  
  if (isLastSlide && isFirstSlide) {
    return index === 0; // Highlight only the first slide
  } else {
    return index === this.currentIndex;
  }
}

  
}
