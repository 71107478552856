<section>
  <div class="thank-you-bg my-4">
        <div class="content">
          <div class="thank_wrapper">
            <img loading="lazy" src="assets/images/thank-you.jpg" alt="thank-you"/>
            <h1 class="animate-charcter">
              Thank Y<i class="far fa-grin-hearts bounce"></i>u!
            </h1>
            <p>
              <!-- Congratulation, <i class="fa fa-thumbs-o-up" style="font-size:24px; transform: translateY(2px);"></i> <br> -->
    We’re glad to inform you that we have confirmed your payment,
    have a great day. Thank you for visiting here.
            </p>
            <!-- <a href="#" class="go-home">Home Page</a> -->
            <!-- <div id="cursor"></div> -->
          </div>
        </div>
      </div>
</section>