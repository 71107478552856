<!-- ------------------Page Heading Start------------- -->

<div class="page_heading">
    <div class="container">
        <h2>Portfolio</h2>
    </div>
</div>

<!-- ------------------Page Heading End------------- -->
<!-- -------------------------Portfolio Section Start----------------- -->

<section class="portfolio_section">
    <div class="container">
        <div class="row">
            <div></div>
            <mat-tab-group>
                <mat-tab label="All" class="row">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-sm-6 col-12 mr-0" *ngFor="let item of data">
                                <div class="portfolio_item">
                                    <img loading="lazy" class="view" [src]="item.imz" alt="item"/>
                                    <div class="portfolio_info">
                                        <div class="project_name">{{ item.name }}</div>
                                        <!-- <div class="project_technologi">Build In PHP</div> -->
                                        <a [href]="item.link" class="project_link">View Live</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Web">
                    <!-- <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div class="portfolio_item">
                            <img loading="lazy" class="view" src="/assets/images/switcher.jpg" />
                            <div class="portfolio_info">
                                <div class="project_name">BillExperts</div>
                                <div class="project_tipe">Bill Payment Web Application</div>
                                <div class="project_technologi">Build In PHP</div>
                                <a href="#" class="project_link">View Live</a>
                            </div>
                        </div>
                    </div> -->
                </mat-tab>
                <mat-tab label="Word Press">
                    <!-- <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div class="portfolio_item">
                            <img loading="lazy" class="view" src="/assets/images/ueue_central.jpg" />
                            <div class="portfolio_info">
                                <div class="project_name">Queue central</div>
                                <div class="project_tipe">Bill Payment Web Application</div>
                                <div class="project_technologi">Build In PHP</div>
                                <a href="#" class="project_link">View Live</a>
                            </div>
                        </div>
                    </div> -->
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</section>

<!-- -------------------------Portfolio Section End----------------- -->