import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { product, products } from '../constant';
import { Title } from '@angular/platform-browser';
import { capatelize } from '../SEO_Constant/routes.constant';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent implements OnInit {
  id?: string;
  ourProducts: products = products
  detail?: product;
  bannerArray:any;
  constructor(private title : Title, private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.detail = this.ourProducts?.[(this.route.snapshot.paramMap.get('name') as string)]
    this.bannerArray = this.detail?.bannerDetail
    this.title.setTitle(capatelize(this.detail?.heading) +' | Resiliencesoft')
  }

}
