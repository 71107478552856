<div>
    <div class="popup">
        <a class="close" mat-button mat-dialog-close>&times;</a>
        <div class="row  p-0 m-0">
            <div class="col-sm-6 p-0">
                <img loading="lazy" [src]="src">
            </div>
            <div class="col-sm-6 ">
                <div class="sidebar">
                    <div class="block clearfix">
                        <h3 class="ab_sec_heading-pop mb-4"><b>Get in Touch</b></h3>
                        <h5>Looking for a job? Please visit at <a class="page_link" mat-button mat-dialog-close
                                href="/careers">Careers</a></h5>
                        <form [formGroup]="formData" class="my_detail" method="post" enctype="multipart/form-data">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label for="email">Full Name</label>
                                        <input type="text" formControlName="Name" class="form-control-job user_icon"
                                        (keypress)="noSpace($event)"
                                            id="name" />
                                        <div id="formError" *ngIf="fullName && fullName.invalid && fullName.touched ">
                                            <span *ngIf="fullName.errors?.['required']">
                                                <i class="fa fa-exclamation-triangle" style="color: #fe3061;" aria-hidden="true"></i> Required
                                            </span>
                                            <span *ngIf="fullName.errors?.['pattern'] && !fullName.errors?.['required']">
                                                <i class="fa fa-exclamation-triangle" style="color: #fe3061;" aria-hidden="true"></i>  Invalid Name input </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label for="email">Phone Number</label>
                                        <input type="text" formControlName="Number" class="form-control-job phone_icon"
                                            id="Number" [maxlength]="10"/>
                                        <div id="formError" *ngIf="contact && contact.invalid && contact.touched">
                                            <span *ngIf="contact.errors?.['required']">
                                                <i class="fa fa-exclamation-triangle" style="color: #fe3061;" aria-hidden="true"></i>  Required
                                            </span>
                                            <span *ngIf="contact.errors?.['pattern'] && !contact.errors?.['required']">
                                                <i class="fa fa-exclamation-triangle" style="color: #fe3061;" aria-hidden="true"></i>  Invalid input </span>
                                            <span *ngIf="(contact.errors?.['minlength'] || contact.errors?.['maxlength']) && !contact.errors?.['pattern']">
                                                <i class="fa fa-exclamation-triangle" style="color: #fe3061;" aria-hidden="true"></i>  Phone No. Must be 10 Digit </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label for="email">Email</label>
                                        <input type="email" formControlName="Email" class="form-control-job mail_icon"
                                            id="mail" />
                                        <div id="formError" *ngIf="email && email.invalid && email.touched ">
                                            <span *ngIf="email.errors?.['required']">
                                                <i class="fa fa-exclamation-triangle" style="color: #fe3061;" aria-hidden="true"></i>  Required
                                            </span>
                                            <span *ngIf="email.errors?.['pattern'] && !email.errors?.['required']">
                                                <i class="fa fa-exclamation-triangle" style="color: #fe3061;" aria-hidden="true"></i>  Invalid email </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label for="email">Breif Requirements</label>
                                        <textarea class="form-control-job requirements_icon" formControlName="msg"
                                        (keypress)="noSpace($event)" id="Number" style="height: 90px;"></textarea>
                                        <div id="formError" *ngIf="msg && msg.invalid && msg.touched">
                                            <span *ngIf="msg.errors?.['required']">
                                                <i class="fa fa-exclamation-triangle" style="color: #fe3061;" aria-hidden="true"></i>  Required
                                            </span>
                                            <span *ngIf="msg.errors?.['minlength'] && !msg.errors?.['pattern']">
                                                <i class="fa fa-exclamation-triangle" style="color: #fe3061;" aria-hidden="true"></i>  Minimum 10 Words are Required </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="alert alert-success" id="success" style="display: none;"></div>
                            <div class="alert alert-danger" id="failed" style="display: none;"></div>
                            <input type="button" [disabled]="formData.invalid" (click)="SubmitEvent()" value="Submit"
                                class="submit-button btn btn-default">
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
