import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TrustedBTNDialog } from '../page/trusted';

@Component({
  selector: 'app-trust-btn-modal',
  templateUrl: './trusted.component.html',
  styleUrls: ['../Trusted-site.css']
})
export class TrustBtnModalComponent implements OnInit{
  constructor(public dialog: MatDialog) {
  }
  ngOnInit(): void {
    // if(!localStorage.getItem('opened')){
    //   const dialogRef = this.dialog.open(DialogContentContactBTNDialog);
    //   dialogRef.afterClosed().subscribe(result => {
    //     console.log(`Dialog result: ${result}`);
    //   });
    //   localStorage.setItem('Dialogopen','welcomedialog')
    //   localStorage.setItem ('opened','true')
    // }
  }

  openDialog() {
    const dialogRef = this.dialog.open(TrustedBTNDialog);
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
