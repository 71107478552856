export interface product {
  img: image;
  sitelink: string;
  description: string;
  heading: string;
  features: feature;
  bannerDetail: banner;
}
interface image {
  img1: string;
  img2: string;
  img3: string;
}
interface feature {
  featureDetail: {
    title1: string;
    features1: string;
    title2: string;
    features2: string;
    title3: string;
    features3: string;
  };
}
interface banner {
  bannerTXT: { title: string }[];
  bannerImg: {
    banner: string;
    bannerMobileImg: string;
    backgroundImg: string;
  };
}
export interface products {
  [key: string]: product;
}
export const products: products = {
  housiemania: {
    img: {
      img1: 'assets/images/numbers.jpg',
      img2: 'assets/images/tickets.jpg',
      img3: 'assets/images/happy.jpg'
    },
    sitelink: 'https://www.example.com/housie-mania', // Add your actual site link
    description: 'Introducing Housie Mania - Your Ultimate Tambola Housie Extravaganza! 🎉\n\nGet ready to embark on an electrifying journey into the world of Tambola Housie like never before. Housie Mania is here to revolutionize your Housie experience, bridging the timeless game with the modern era and making it accessible to everyone, anywhere, anytime.\n\nExperience unparalleled convenience in generating random numbers for Bingo, Tambola, or Housie with our state-of-the-art and user-friendly tool. Play in real-time with friends using offline tickets, taking the excitement of Housie to a whole new level.',
    heading: 'Housie Mania',
    features: {
      featureDetail: {
        title1: 'Housie Number Generator',
        features1: 'Our tool generates numbers for your Housie game really simple and fair. It guarantees that the numbers are chosen randomly, making your Housie experience easy and enjoyable.',
        title2: 'Realtime Offline Tickets',
        features2: 'With our tool, you can scan your Housie tickets in no time. And mark your numbers as you go, allowing you to enjoy Housie with friends and have fun whenever and wherever you want.',

        title3: 'Fun Challenges',
        features3: 'Play in games with exciting twists, and enjoy marking off numbers to win. Housie Mania is like an adventurous journey where you will have a great time.',
      },
    },
    bannerDetail: {
      bannerTXT: [
        {
            title: 'Try the Housie Number Generator for extra fun.',
        },
        {
            title: 'Play with friends using real-time offline tickets.',
        },
        {
            title: 'Take part in exciting challenges to test your Housie skills.',
        },
        {
            title: 'Begin your Housie adventure right now!'
        },
    ],
    
      bannerImg: {
        banner: 'assets/images/housieMania1.png', // Adjust the image path accordingly
        bannerMobileImg: 'assets/images/housieManiaMobileBanner.png',
        backgroundImg: 'assets/images/housieManiaBackground.jpg',
      },
    },
  },

  sharpquiz: {
    img: {
      img1: 'assets/images/sharpQuiz1.jpg',
      img2: 'assets/images/sharpQuiz2.jpg',
      img3: 'assets/images/sharpQuiz3.jpg'
    },
    sitelink: 'https://www.example.com/sharp-quiz', // Add your actual site link
    description: 'Welcome to Sharp Quiz, your go-to platform to master programming skills and expand general knowledge. Explore diverse quiz categories, track your progress in real-time, and embrace engaging challenges.',
    heading: 'Sharp Quiz',
    features: {
      featureDetail: {
        title1: 'Explore Diverse Quiz Categories',
        features1: 'Sharp Quiz offers an extensive range of quiz categories, from programming to general knowledge, empowering you to test and enhance your skills with Sharp Quiz.',
        title2: 'Real-Time Progress Tracking',
        features2: 'Stay ahead with real-time tracking of your quiz progress, including scores, timing, and performance comparisons. Join Sharp Quiz for a competitive edge.',
        title3: 'Embrace Engaging Challenges',
        features3: 'Engage with challenging questions that foster skill mastery and knowledge improvement in the domains of programming and general knowledge. Discover the world of Sharp Quiz.',
      },
    },
    bannerDetail: {
      bannerTXT: [
        {
          title: 'Master programming skills and expand your knowledge with Sharp Quiz.',
        },
        {
          title: 'Track progress, scores, and compare performance in real-time.',
        },
        {
          title: 'Explore diverse quiz categories, from programming to general knowledge.',
        },
        {
          title: 'Challenge yourself with thought-provoking questions. Get started today!',
        },
      ],

      bannerImg: {
        banner: 'assets/images/sharpQuiz1.png', // Adjust the image path accordingly
        bannerMobileImg: 'assets/img/sharpQuizMobileBanner.png',
        backgroundImg: 'assets/img/sharpQuizBackground.jpg',
      },
    },
  },


  ecommerce: {
    img: {
      img1: 'assets/img/Ecommerce_1.png',
      img2: 'assets/img/Ecommerce_2.png',
      img3: 'assets/img/Ecommerce_3.png'
    },
    sitelink: '',
    description: '',
    heading: 'Ecommerce',
    features: {
      featureDetail: {
        title1: 'Advanced Search Function and Filtering',
        features1: 'Ecommerce search features and filtering are top ecommerce website design features as they enable customers to find the products they are looking for quickly and with ease. filters allow shoppers to narrow down product lists and focus on the products they’re actually interested in.',
        title2: 'Global Reach',
        features2: 'E-commerce technologies enable a business to easily reach across geographic boundaries around the earth far more conveniently and effectively as compared to traditional commerce. Globally, companies are acquiring greater profits and business results by expanding their business with e-commerce solutions.',
        title3: 'Secure Payment Options ',
        features3: 'the payment option is one of the most important ecommerce website features. A credible ecommerce store should have a secure payment gateway such as PayPal, Paytm . So it might be a good idea to add Apple Pay or Google Pay to your checkout to capture more orders from mobile devices.',
      },
    },
    bannerDetail: {
      bannerTXT: [
        {
          title: 'It is exchange and storing of data in a constant, format which enable easy exchange of information.',
        },
        {
          title: 'the payment option is one of the most important ecommerce website features.',
        },
        {
          title: 'Enable a business to easily reach across geographic boundaries around the earth.',
        },
        {
          title: 'Enable customers to find the products they are looking for quickly and with ease.',
        },
      ],
      bannerImg: {
        banner: 'assets/img/imageBanner.png',
        bannerMobileImg: 'assets/img/mobile-banner.png',
        backgroundImg: 'assets/img/banner-background.jpg',
      },
    },
  },
  grocery: {
    img: {
      img1: 'assets/img/Grocery_1.png',
      img2: 'assets/img/Grocery_2.png',
      img3: 'assets/img/Grocery_3.png'
    },
    sitelink: '',
    description: '',
    heading: 'Grocery with Delivery system',
    features: {
      featureDetail: {
        title1: 'Easy registration',
        features1: 'The registration process has to be simple and user-friendly. The registration through social media login is popular nowadays. This feature can be added to the registration process. Other options are through email id and mobile number. When logged in through the social media, the options to share any offers makes it a good advertising for the grocery delivery app.',
        title2: 'Search Groceries',
        features2: 'This is one of the most prominent and essential features of a grocery delivery system. After a user is logged in to the app, the user will search and look for grocery items or even local grocery stores nearby.you have a smart list of all the grocery items and grocery stores organized by name, location, type of grocery, product preferences (fruits, vegetables, packaged foods), etc. With this smart list, it will be much easier for the user to search for what they want to order. They can go through the lists, select a grocery vendor nearby and place the order.',
        title3: 'Payment Options',
        features3: 'The secure multiple payment options will include card payment, cash on delivery, or mobile wallet. This will make the purchase easy for them as per their convenience. Ask your grocery delivery app development company to integrate multiple payment gateways in your grocery app to enable the people to make payments as they wish.',
      },
    },
    bannerDetail: {
      bannerTXT: [
        {
          title: 'The secure multiple payment options will include card payment, cash on delivery, or mobile wallet. ',
        },
        {
          title: 'The registration process has to be simple and user-friendly.',
        },
        {
          title: ' The registration through social media login is popular nowadays.',
        },
        {
          title: 'This is one of the most prominent and essential features of a grocery delivery system.',
        },
      ],
      bannerImg: {
        banner: 'assets/img/imageBanner.png',
        bannerMobileImg: 'assets/img/mobile-banner.png',
        backgroundImg: 'assets/img/banner-background.jpg',
      },
    },
  },
  food: {
    img: {
      img1: 'assets/img/Food_1.png',
      img2: 'assets/img/Food_2.png',
      img3: 'assets/img/Food_3.png'
    },
    sitelink: '',
    description: '',
    heading: 'Food Ordering, Delivery System',
    features: {
      featureDetail: {
        title1: 'Easy Search:',
        features1: 'Make sure all the restaurants are listed smartly on your app and the users don’t have to keep navigating aimlessly without success. Organize everything in the best possible way, by cuisine, food item, rating, etc. Use smart lists and detailed filters to make the customers’ lives easier on the app and share recommendations with them based on the cuisine, food type, rating, dish and more.',
        title2: 'Add to Cart and Order Management',
        features2: 'Customers take time selecting the dishes they wish to order and it is a good idea to enable add to cart features with a single click. They can change the items several times before finally placing the order and this feature comes in handy.Additionally, you can make things more convenient by offering an order scheduling feature. It allows them to get food delivered at a specific time.',
        title3: 'Reviews and Ratings',
        features3: ' Customers love to be heard. Let them rate their experience with the order after the delivery is completed. It helps you in building a detailed view of the restaurants’ service and lets you know about any weaknesses in your current delivery system.',
      },
    },
    bannerDetail: {
      bannerTXT: [
        {
          title: 'customers should be able to track their orders in real-time.',
        },
        {
          title: 'Organize everything in the best possible way, by cuisine, food item, rating, etc.',
        },
        {
          title: 'Apart from being simple and convenient, payments should always be completed in a secure manner.',
        },
        {
          title: 'customers can change the items several times before finally placing the order and this feature comes in handy.',
        },
      ],
      bannerImg: {
        banner: 'assets/img/mobile-banner.png',
        bannerMobileImg: 'assets/img/mobile-banner.png',
        backgroundImg: 'assets/img/banner-background.jpg',
      },
    },
  },
  school: {
    img: {
      img1: 'assets/img/School_1.png',
      img2: 'assets/img/School_2.png',
      img3: 'assets/img/School_3.png'
    },
    sitelink: '',
    description: '',
    heading: 'School Management',
    features: {
      featureDetail: {
        title1: 'Admission Management:',
        features1: 'This method can help the administration access the data instantly whenever required. Such software is capable enough to manage the registration and admission process of both old and new students smoothly.',
        title2: 'Fee Management System',
        features2: 'Fee management is one of the most vital components of the smooth operation of a school system,  fee management software solutions out there, with distinct fee category distinction, fee installment management, timely reminders being sent, and so much more. This makes the whole process of fee payment completely hassle-free for both parents as well as the school administration.',
        title3: 'Student Information',
        features3: ' Attendance, homework, discipline, grades, and achievements! Almost all the information regarding the students can be accessed easily using efficient school management software. Teachers can use the student database to avail basic information of students like grades, address, information regarding parents and siblings etc. In addition to this information, details regarding the students’ medical history, accounts, billing etc. are also added by the administration.',
      },
    },
    bannerDetail: {
      bannerTXT: [
        {
          title: 'attendance management software solution that makes life much easier for teachers. ',
        },
        {
          title: ' database to keep the details regarding class timings, student progress, and classroom activities in one place. ',
        },
        {
          title: 'whole process of fee payment completely hassle-free for both parents as well as the school administration.',
        },
        {
          title: 'This method can help the administration access the data instantly whenever required.',
        },
      ],
      bannerImg: {
        banner: 'assets/img/School_4.png',
        bannerMobileImg: 'assets/img/mobile-banner.png',
        backgroundImg: 'assets/img/banner-background.jpg',
      },
    },
  },
  service: {
    img: {
      img1: 'assets/img/Ordering_System_1.png',
      img2: 'assets/img/Ordering_System_2.png',
      img3: 'assets/img/Ordering_System_3.png'
    },
    sitelink: '',
    description: '',
    heading: 'Service Ordering System',
    features: {
      featureDetail: {
        title1: 'Scheduling and dispatch',
        features1: 'Assigns technicians to jobs, either manually by dispatchers or automatically, based on location, availability and skill set. Many systems send automatic notifications to technicians via email, phone or in-app alerts, along with relevant work orders.',
        title2: 'Mobile functionality',
        features2: 'Allows technicians to receive real-time alerts and notifications for upcoming or past-due work orders. This includes the ability to view customer and job information, service history for the customer or location, update inventory with parts used, view photos or schematics, generate invoices, capture customer signatures and more from anywhere in the field. ',
        title3: 'GPS capabilities',
        features3: 'some systems come with GPS capabilities that are able to locate and monitor technicians on the map and provide turn-by-turn directions to service locations. This technology can also help keep technicians accountable while in the field. Consider a GPS-enabled system if you frequently send technicians to unfamiliar locations, and/or if you want to keep closer tabs on field service workers.',
      },
    },
    bannerDetail: {
      bannerTXT: [
        {
          title: 'customers and other stakeholders log in any time, from anywhere, to access scheduling and repair information.',
        },
        {
          title: 'Allows users to run reports to gain deeper insight into service operations and technician performance.',
        },
        {
          title: 'Allows technicians to receive real-time alerts and notifications for upcoming or past-due work orders.',
        },
        {
          title: 'GPS capabilities that are able to locate and monitor technicians on the map.',
        },
      ],
      bannerImg: {
        banner: 'assets/img/mobile-banner.png',
        bannerMobileImg: 'assets/img/mobile-banner.png',
        backgroundImg: 'assets/img/banner-background.jpg',
      },
    },
  },
  inventory: {
    img: {
      img1: 'assets/img/Ecommerce_1.png',
      img2: 'assets/img/Ecommerce_2.png',
      img3: 'assets/img/Ecommerce_3.png'
    },
    sitelink: '',
    description: '',
    heading: 'Inventory and Billing',
    features: {
      featureDetail: {
        title1: 'Systematic & Organized Inventory',
        features1: 'The recording and maintenance of inventory through the Inventory Management System keep things organized and systematic Inventory management Software tracks the stocks on multiple sales channels. the Inventory Management System lets you know about the complete inventory status of your manufacturing unit with a few clicks of the mouse.',
        title2: 'Easy & Fast Billing in Minutes',
        features2: 'Needless to say, manual billing is a tedious job and time-consuming. Verifying each transaction from the ledger could be a challenge. Billing software lets you generate multiple invoices in a few minutes. You can simply scan the barcodes and the details of the products are added automatically. You are also rescued from all the complex calculations.',
        title3: 'Billing and Inventory Management',
        features3: 'Billing and Inventory Management software works as the heartbeat of any retail business. It allows you to keep track of all business operations from anywhere. It ensures that your business runs efficiently and smoothly. Billing software automates the recurring process of billing for services and products purchased and sold. Inventory management software offers a complete set of inventory management functionalities, including purchasing capabilities that help streamline the supply chain process.',
      },
    },
    bannerDetail: {
      bannerTXT: [
        {
          title: 'Allow us to keep track of all business operations from anywhere',
        },
        {
          title: 'Inventory management Software tracks the stocks on multiple sales channels',
        },
        {
          title: 'It ensures that your business runs efficiently and smoothly',
        },
        {
          title: 'Billing software lets you generate multiple invoices in a few minutes',
        },
      ],
      bannerImg: {
        banner: 'assets/img/mobile-banner.png',
        bannerMobileImg: 'assets/img/mobile-banner.png',
        backgroundImg: 'assets/img/banner-background.jpg',
      },
    },
  },
  path: {
    img: {
      img1: 'assets/img/Path_Lab_1.png',
      img2: 'assets/img/Path_Lab_2.png',
      img3: 'assets/img/Path_Lab_3.png'
    },
    sitelink: '',
    description: '',
    heading: 'Path Lab System',
    features: {
      featureDetail: {
        title1: '',
        features1: 'PathLab is an integrated website to manage the medical laboratories system, as it fulfills all the needs of medical laboratories from all their professional aspects, as it allows the users of the system to enter the results of medical reports and facilitates communication and cooperation between laboratory employees and extracts all the required reports from the laboratory and also allow patients to follow up their medical reports, lab branches, and submit a home visit request.',
        title2: '',
        features2: 'Managing Tests. Managing Cultures & Antibiotics. Managing Tests & Cultures price list. Doctors management. Patient management. Generating patient receipt. Full management of patient test report. Printing patient receipt & test report. Managing patient home visit request. Displaying home visit schedule per day.Creating contracts with discounts.',
        title3: '',
        features3: 'Multi-user with different roles. Multi-branch laboratory. The accounting module includes (Expenses, Income, Profits). Laboratory configuration settings. Laboratory configuration settings. Patient profile management. Ability to view patient test reports & receipts. Sending patient home visit request. Viewing laboratory branches.',
      },
    },
    bannerDetail: {
      bannerTXT: [
        {
          title: 'Full management of patient test report.',
        },
        {
          title: 'Managing patient home visit request.',
        },
        {
          title: 'Managing Tests & Cultures price list.',
        },
        {
          title: 'Ability to backup database.',
        },
      ],
      bannerImg: {
        banner: 'assets/img/mobile-banner.png',
        bannerMobileImg: 'assets/img/mobile-banner.png',
        backgroundImg: 'assets/img/banner-background.jpg',
      },
    },
  },
  crm: {
    img: {
      img1: 'assets/img/crm_1.png',
      img2: 'assets/img/crm_2.png',
      img3: 'assets/img/crm_3.png'
    },
    sitelink: '',
    description: '',
    heading: 'CRM and HRM',
    features: {
      featureDetail: {
        title1: '',
        features1:
          'Manage customers and leads. Track client projects, invoices, proposals/estimates  Add your company’s employees, track their attendance and manage their leaves 	Create projects, add project members and track the project progress, expenses, earnings, timelogs, tasks, and milestones',
        title2: '',
        features2:
          'Kanban taskboard to visualize work and tasks Project Gantt chart to plan the project schedule. Convert the invoices to credit notes which can be redeemed. Tickets can be raised by employees and clients which are assigned to ticket agents to get it resolved.Messages section for internal chats ',
        title3: '',
        features3:
          'Slack, pusher and one signal integration for real time notifications. Theme settings to style the app to reflect your. Receive payments via PayPal, Stripe and Razorpay gateways. Create custom roles with custom permissions for employees. Various reports for tasks, timelogs, finance, attendance, leaves. Tons of other configurable options',
      },
    },
    bannerDetail: {
      bannerTXT: [
        {
          title: 'Convert the invoices to credit notes which can be redeemed',
        },
        {
          title: 'Create custom roles with custom permissions for employees.',
        },
        {
          title: 'Messages section for internal chats.',
        },
        {
          title: 'Receive payments via PayPal, Stripe and Razorpay gateways.',
        },
      ],
      bannerImg: {
        banner: 'assets/img/mobile-banner.png',
        bannerMobileImg: 'assets/img/mobile-banner.png',
        backgroundImg: 'assets/img/banner-background.jpg',
      },
    },
  },
  digital: {
    img: {
      img1: 'assets/img/Digital_Visiting_1.png',
      img2: 'assets/img/Digital_Visiting_2.png',
      img3: 'assets/img/Digital_Visiting_3.png'
    },
    sitelink: '',
    description: '',
    heading: 'Digital Visiting Card',
    features: {
      featureDetail: {
        title1: '',
        features1: 'The purpose of a digital business card is simple to help you connect with more potential clients, partners, and co-workers. It’s convenient, fast and secure. You can view it on every device from mobile phone to laptop. share on multiple social media platforms like What’s App, Facebook, etc. I’m sure you’re already familiar with traditional business cards, the printed product that you might hand to a potential client or colleague',
        title2: '',
        features2: 'It’s a perfect way of networking and leaving your contact details to connect later on. Digital business cards serve primarily the same purpose. Most of us are on so many platforms (LinkedIn, Twitter, Instagram, and more) that it can be overwhelming to share all of those details at once. That’s where virtual business cards shine.',
        title3: '',
        features3: 'The point of an e business card is to create a single, simple, shareable file with all of your contact details. That way, the recipient has no friction to follow your account on social media or contact you using the details.',
      },
    },
    bannerDetail: {
      bannerTXT: [
        {
          title: 'It’s convenient, fast and secure..',
        },
        {
          title: 'You can view it on every device from mobile phone to laptop.',
        },
        {
          title: ' share on multiple social media platforms like What’s App, Facebook, etc',
        },
        {
          title: 'It’s a perfect way of networking and leaving your contact.',
        },
      ],
      bannerImg: {
        banner: 'assets/img/mobile-banner.png',
        bannerMobileImg: 'assets/img/mobile-banner.png',
        backgroundImg: 'assets/img/banner-background.jpg',
      },
    },
  },
};
