import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  consultcontroller: boolean = true;

  constructor(private router: Router) {}

  ngOnInit(): void {}
  first?: boolean;
  hidemenu?: boolean;
  isvisible: boolean = false;

  @HostListener('document:click', ['$event.target'])
  documentClick(event: HTMLInputElement) {
    var drop = false;
    if (
      event.className == 'dropdowncontainer showdropdownnow' ||
      event.className == 'down_arrow'
    ) {
      drop = true;
    } else {
      drop = false;
    }
    if (!drop) {
      this.first = false;
      this.isvisible = false;
      this.hidemenu = false;
    }
  }

  changemenu1() {
    this.first = !this.first;
    if (this.first) {
      this.isvisible = false;
    }
  }
  changemenu2() {
    this.isvisible = !this.isvisible;
    if (this.isvisible) {
      this.first = false;
    }
  }

  checkHomeRoute() {
    if (this.router.url === '/') return true;
    return false;
  }
}
