<!-- ------------------Page Heading Start------------- -->

<div class="page_heading">
    <div class="container">
        <h2>Contractual Service</h2>
    </div>
</div>

<!-- ------------------Page Heading End------------- -->
<section >
  <div class="container">
<h1 class="my-4 sub-title">Your Trusted Partner in Software Solutions</h1>
</div>
</section>

<section style="background-color: #f2f2f2; padding: 60px; height: 400px;">
  <div class="container">
    <p style="font-size: 17px;">At Resiliencesoft, we understand the importance of reliable and efficient software solutions for businesses of all sizes. With our contract-based services, we offer tailored solutions to meet your specific needs, ensuring seamless integration and optimal performance.</p>
  </div>
</section>

<section class="portfolio_section">
    <div class="container">
  <section style="margin-top: -200px;box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); background-color: #fff;border-radius: 15px;">
  <h3 class="sub-title" style="padding-top: 50px;">Exclusive Benefits of Contract-Based Developement</h3>

<!-- your-component.component.html -->
<ngx-slick-carousel class="constant-simple-slider" #slickModal="slick-carousel" [config]="carouselConfig" (afterChange)="onAfterChange($event)">
  <div ngxSlickItem *ngFor="let slide of slides; let i = index" >
    <div class="row" style="max-width: 450px; padding-bottom: 50px;">
      <div class="col-12" >
        <div [ngClass]="{'center-slide': isCenterSlide(i)}" class="slide-custom">
        <img [src]="slide.imgSrc" alt="{{ slide.title }}" class="img">
        <h3 class="sub-small-title">{{ slide.title }}</h3>
        <p style="max-width: 380px;">{{ slide.description }}</p>
      </div>
    </div>
    </div>
  </div>
</ngx-slick-carousel>



</section>




<section>
    <h3 class="sub-title">Our Process</h3>
  <ul class="process">
    <li class="process__item">
      <span class="process__number">1</span>
      <span class="process__title">Understanding Your Needs</span>
      <span class="process__subtitle">We start by thoroughly understanding your project requirements, goals, and timelines to ensure we provide the right resources.</span>
    </li>
  
    <li class="process__item">
      <span class="process__number">2</span>
      <span class="process__title">Resource Matching</span>
      <span class="process__subtitle">We carefully select developers from our talent pool whose skills and experience match your project needs.</span>
    </li>
  
    <li class="process__item">
      <span class="process__number">3</span>
      <span class="process__title">Onboarding and Integration</span>
      <span class="process__subtitle"> Our developers seamlessly integrate with your existing team and processes, ensuring a smooth transition and immediate productivity.</span>
    </li>
  
    <li class="process__item">
      <span class="process__number">4</span>
      <span class="process__title">Continuous Support</span>
      <span class="process__subtitle">Throughout the project, we provide continuous support to ensure everything runs smoothly and your expectations are met.</span>
    </li>
  </ul>
  </section>


<section>
  <h3 class="sub-title">Our Approach</h3>
  <div class="row">
    <div class="col-sm-4">
      <div class="grid">
        <figure class="effect-julia">
          <img src="../../assets/img/Flexible-Software_web.webp" alt="img21">
          <figcaption>
            <h2>Flexible Terms</h2>
            <div>
              <p class="product-text">Our contract-based services offer flexibility to accommodate your changing needs and budgetary constraints, ensuring you get the most value for your investment.</p>
            </div>
            <a href="#">View more</a>
          </figcaption>			
        </figure>
        
      </div>
  </div>
    <div class="col-sm-4">
    <div class="grid">
      <figure class="effect-julia">
        <img src="../../assets/img/shutterstock_29409105523.jpg" alt="img21">
        <figcaption>
          <h2>Transparent Pricing</h2>
          <div>
            <p class="product-text"> We believe in transparent pricing with no hidden costs or surprises, so you know exactly what to expect from the outset.</p>
          </div>
          <a href="#">View more</a>
        </figcaption>			
      </figure>
      
    </div>
    </div>
    <div class="col-sm-4">
    <div class="grid">
      <figure class="effect-julia">
        <img src="../../assets/img/Flexible-Companies-with-Return-to-Work-Programs.jpg" alt="img21">
        <figcaption>
          <h2>Dedicated Support</h2>
          <div>
            <p class="product-text">With dedicated account managers and support staff, we are committed to providing personalized attention and support throughout the duration of your contract.</p>
          </div>
          <a href="#">View more</a>
        </figcaption>			
      </figure>
      
    </div>
    </div>
  </div>
</section>
<section>
    <h3 class="sub-title">Get Started Today</h3>
    <p>Ready to take your business to the next level with our contract-based software services? Contact us today to schedule a consultation and see how Resiliencesoft can help drive your success.</p>
  </section>
  </div>
  </section>