import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { APIcalls } from '../Services/api';
import { APIService } from '../Services/main-service.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  activeBar = 'bydefoult';
  stripeform : FormGroup;
  razorpayform: FormGroup;

  // stripe
  Stripeisvalid: boolean = false;
  stripe : any;
  elements: any;
  submitdisabled: any;
  spinner: boolean = true;
  form: HTMLFormElement;
  iswaiting: boolean = false;
  isBrowser: boolean;

  constructor( private title : Title, private fb : FormBuilder, private api : APIService, private route : Router, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
    if(this.isBrowser){

      this.form = (<any>window).document.getElementById('payment-form');
    }
    this.stripeform = this.fb.group ({
      name : ['', [Validators.required, Validators.pattern('[a-zA-Z- ]*')]],
      mail : ['', [Validators.required, Validators.pattern(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)]],
      currency : ['inr'],
      price : ['', [Validators.required]],
    })

    this.razorpayform = this.fb.group ({
      name : ['', [Validators.required, Validators.pattern('[a-zA-Z- ]*')]],
      mail : ['', [Validators.required, Validators.pattern(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)]],
      price : ['', [Validators.required]],
      currency : ['INR'],
      phone : ['', [Validators.required, Validators.pattern('^[0-9]*$'), this.validatePhoneLength]],

    })
  } 
  paymentHandler:any = null;

  ngOnInit(): void { //https://checkout.razorpay.com/v1/checkout.js
    this.title.setTitle('Payment | Resiliencesoft')
  this.loadScript('https://checkout.razorpay.com/v1/checkout.js');

  }
  public loadScript(url: string) {
    if(this.isBrowser){
      const body = <HTMLDivElement> document.body;
      const script = document.createElement('script');
      script.innerHTML = '';
      script.src = url;
      script.async = false;
      script.defer = true;
      body.appendChild(script);
    }
  }

  get Sname(){
    return this.stripeform.get('name')
  }
  get Smail(){
    return this.stripeform.get('mail')
  }
  get Sprice(){
    return this.stripeform.get('price')
  }
  get Scurrency(){
    return this.stripeform.get('currency')
  }

  preventNonNumeric(event: KeyboardEvent): void {
    const charCode = event.which ? event.which : event.keyCode;
    const input = event.target as HTMLInputElement;
    
    // Only allow numbers and prevent input if already at max length
    if ((charCode < 48 || charCode > 57) || input.value.length >= 10) {
      event.preventDefault();
    }
  }

  validatePhoneInput(event: any): void {
    const input = event.target;
    let value = input.value;
    
    // Remove non-numeric characters
    value = value.replace(/[^0-9]/g, '');
    
    // Truncate to maximum length of 10 digits
    if (value.length > 10) {
      value = value.substring(0, 10);
    }

    input.value = value;
    this.razorpayform.controls['phone'].setValue(value);
  }

  validatePhoneLength(control: AbstractControl): ValidationErrors | null {
    if (control.value && control.value.length < 10) {
      return { minlength: true };
    }
    return null;
  }


  changeTab(tab: any){
    this.activeBar = tab;
    this.Stripeisvalid = false
    if(tab == 'stripe')
    this.loadScript('https://js.stripe.com/v3/');
    }

  initializePayment(){
    if(this.stripeform.valid){
    this.stripe = (<any>window).Stripe("pk_live_3SxDyyFGOwRNUwjZHY87WT4j")
    this.initialize();
    this.checkStatus();
    this.Stripeisvalid = !this.Stripeisvalid
    this.iswaiting = true;
    }else {
      this.markFormGroupTouched(this.stripeform);
    }
  }

  initialize() {
    var formData: any = new FormData();

    formData.append('price', Number(this.Sprice?.value) * 100);
    formData.append('currency', this.Scurrency?.value);

    this.api.postData('stripepayment', formData).subscribe((r: any) => {
      this.elements = this.stripe.elements(r);
      const paymentElement = this.elements.create("payment");
      paymentElement.mount("#payment-element");
      paymentElement.on('ready', (event: any) => {
        this.iswaiting = false;
      });
    });
  }

  handleSubmit(e:any) {
    var elements = this.elements;
    e.preventDefault();
    this.setLoading(true);

    const error = this.stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://www.resiliencesoft.com/#/payment/success",
      },
    });
    const err = error
    console.log(err.stringify())
    if (error.__zone_symbol__value.error.type === "card_error" || error.__zone_symbol__value.error.type === "validation_error") {
      console.log(error.message);
    } else {
      console.log("An unexpected error occured.");
    }

    this.setLoading(false);
  }

  // razorpay

  get Rname(){
    return this.razorpayform.get('name')
  }
  get Rmail(){
    return this.razorpayform.get('mail')
  }
  get Rprice(){
    return this.razorpayform.get('price')
  }
  get Rcurrency(){
    return this.razorpayform.get('currency')
  }
  get Rphone(){
    return this.razorpayform.get('phone')
  }



rzp1: any;
pay(){
  if(this.razorpayform.valid){
  var options = {
    "key": "rzp_live_Wzqp1sGfOCUIbZ",
    "amount": (Number(this.razorpayform.value.price)*100).toString(),
    "currency": this.razorpayform.value.currency,
    "name": "ResilienceSoft",
    "description": "Test Transaction",
    "image": "../../favicon.ico",
    "handler": (res: any)=>{
      if(res){
        var data: any;
        this.api.postDataRazor(res.razorpay_payment_id, { amount: this.Rprice?.value, currency: this.Rcurrency?.value }).subscribe((res)=>{
          data = res;
        })
        const formData = new FormData();
        formData.append('name',this.Rname?.value)
        formData.append('email',this.Rmail?.value)
        formData.append('source','razor pay')
        formData.append('Tid',res.razorpay_payment_id)
        formData.append('amount',this.Rprice?.value)
        formData.append('currency',this.Rcurrency?.value)
        formData.append('res',JSON.stringify(data))
        this.api.postData(APIcalls.mailApi.payment, formData).subscribe()
        this.api.openSnackBar(" Thank You !, Your Payment For Rs"+ this.razorpayform.value.price +" Is Recorded Successfully ",5)
        this.route.navigateByUrl('https://www.resiliencesoft.com/#/payment/success')
      }
    },
    "prefill": {
      "name": this.razorpayform.value.name,
      "email": this.razorpayform.value.mail,
      "contact": this.Rphone?.value
    },
    "notes": {
      "address": "Razorpay Corporate Office"
    },
    "theme": {
      "color": "#3399cc"
    }
  };
  this.rzp1 = new this.api.nativeWindow.Razorpay(options);
  this.rzp1.open()
  }else {
    this.markFormGroupTouched(this.razorpayform);
  }
}

private markFormGroupTouched(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach(key => {
    const control = formGroup.get(key);
    control?.markAsTouched();
  });
}
  // only for stripe



  setLoading(isLoading: any) {
    if (isLoading) {
      // Disable the button and show a spinner
      this.submitdisabled = true;
      this.spinner = false;
    } else {
      this.submitdisabled = false;
      this.spinner = true;
    }
  }

  async checkStatus() {
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    const { paymentIntent } = await this.stripe.retrievePaymentIntent(clientSecret);

    switch (paymentIntent.status) {
      case "succeeded":
        this.api.openSnackBar("Payment succeeded!",5);
        break;
      case "processing":
        this.api.openSnackBar("Your payment is processing.",5);
        break;
      case "requires_payment_method":
        this.api.openSnackBar("Your payment was not successful, please try again.",5);
        break;
      default:
        this.api.openSnackBar("Something went wrong.",5);
        break;
    }
  }
}
