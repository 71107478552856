import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { capatelize } from '../SEO_Constant/routes.constant';


@Component({
  selector: 'app-our-products',
  templateUrl: './our-products.component.html',
  styleUrls: ['./our-products.component.css']
})
export class OurProductsComponent implements OnInit {
  titles:any
  value = 5; //addition of .5
  starList: string[] = [];
  cardDetails : any = [
    {
      image:"assets/images/housieM.png",
      tittle:"HousieMania",
      details:"Introducing Housie Mania - Your Ultimate Tambola Housie Extravaganza!",
     rating : 5

    },
    {
      image:"assets/images/sharpQ.png",
      tittle:"SharpQuiz",
      details:"Sharp Quiz - Master Your Programming Skills and General Knowledge.",
     rating : 4.8

    },
    {
      image:"assets/images/ourproducts_ecommerce.png",
      tittle:"Ecommerce",
      details:"Enable a business to easily reach across geographic boundaries around the earth.",
      rating : 5

    },
    {
      image:"assets/images/ourproducts_grocery.png",
      tittle:"Grocery with Delivery system",
      details:"The user will search and look for grocery items or even local grocery stores nearby.",
      rating : 5

    },
    {
      image:"assets/images/ourproducts_food.png",
      tittle:"Food Ordering, Delivery System",
      details:" customers should be able to track their orders in real-time.",
      rating : 5

    },
    {
      image:"assets/images/ourproducts_school.png",
      tittle:"School Management",
      details:"This method can help the administration access the data instantly whenever required.",
      rating : 5

    },
    {
      image:"assets/images/ourproducts_service.png",
      tittle:"Service Ordering System",
      details:"Allows technicians to receive real-time alerts and notifications for upcoming or past-due work orders.",
      rating : 5

    },
    {
      image:"assets/images/ourproducts_path.png",
      tittle:"Path Lab System",
      details:"Managing Tests & Cultures price list. Doctors management. Generating patient receipt.",
      rating : 5

    },
    {
      image:"assets/images/ourproducts_crm.png",
      tittle:"CRM and HRM",
      details:"Tickets can be raised by employees and clients which are assigned to ticket agents to get it resolved.",
      rating : 5

    },
    {
      image:"assets/images/ourproducts_digital.png",
      tittle:"Digital Visiting Card",
      details:"It’s a perfect way of networking and leaving your contact details to connect later on.",
      rating : 5
    },
    {
      image:"assets/images/ourproducts_inventory.png",
      tittle:"Inventory and Billing",
      details:"Inventory and Billing Management software works as the heartbeat of any retail business.",
      rating : 5
    }
  ]
  bannercardDetails : any = [
    {
      image:"../../assets/images/housie-mania-logo.png",
      title:"Housie Mania"
    },
    {
      image:"../../assets/images/sharpQuizLogo.png",
      title:"sharp Quiz"
    },
    {
      image:"../../assets/images/eco.png",
      title:"Ecommerce"
    },
    {
      image:"../../assets/images/grocery.png",
      title:"Grocery"
    },
    {
      image:"../../assets/images/food.png",
      title:"Food"
    },
    {
      image:"../../assets/images/education.png",
      title:"Education"
    },
    {
      image:"../../assets/images/service-order.png",
      title:"Order Service"
    },
    // {
    //   image:"../../assets/images/pathLab.png",
    //   title:"Path Lab"
    // },
    {
      image:"../../assets/images/social_media.png",
      title:"Social Media"
    }
  ]
  constructor(private title : Title, private router: Router) {
    this.titles = router.url.split('/')[router.url.split('/').length-1].replace(/-/g,' ')
    // console.log("cons",this.cardDetails)
  }

  ngOnInit(): void {
    this.title.setTitle(capatelize(this.titles) +' | Resiliencesoft')
    // console.log(this.cardDetails,"hii")
   
  }

  getstarList(value: number): string[] {
    const starList: string[] = [];
  
    for (let i = 1; i <= 5; i++) {
      if (i <= value) {
        starList.push("fas fa-star");
      } else if (i <= value + 0.5) {
        starList.push("fas fa-star-half");
      } else {
        starList.push("far fa-star");
      }
    }
  
    return starList;
  }
  
 

  getUrl( card: any ){
    return { 'background-image': ` url('${card.image}') ` }
  }

  gotoDetail(detail:any){
    let name = detail.tittle.toLowerCase( ).split(" ")[0]
    this.router.navigateByUrl('/ourproducts/'+name)
  }

}
