import { Component, OnInit } from "@angular/core";
@Component({
    selector: 'trusted-dialog',
    templateUrl: 'trusted.html',
    styleUrls: ['../Trusted-site.css']
  })
  export class TrustedBTNDialog implements OnInit{
 
  ngOnInit(): void {
  }

  async onSubmit() {
  }
}