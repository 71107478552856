<!-- -------------------------Call Back Section Start----------------- -->

<section class="sscp40">
    <div class="container">
        <div class="section_heading">
            <h2>Connect our Business Expert</h2>
            <p>Heavenly bodies sent you to this page. Now, you need to take an initiative.</p>
            <div class="Consult_btn">
                <app-consult-now-modal id="requestcallbackdialog" class="auto_center">
                </app-consult-now-modal>
            </div>
        </div>
    </div>
</section>

<!-- -------------------------Call Back Section End----------------- -->