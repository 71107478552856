<div class="page_heading">
  <div class="container">
      <h2>{{title}}</h2>
  </div>
</div>
<app-banner [BannerData]="bannerArray"></app-banner>


<!-- -------------------------our-business Section Start----------------- -->
<section class="our-business">
  <div class="row  our-business-heading mt-0">
    <div class="col-sm-12">
      <h2>services</h2>
    </div>
  </div>
<ng-container *ngFor="let data of ourProducts | keyvalue let i=index;">
        <div *ngIf="i % 2 == 0" class="row content-one" [class.mt-0]="i>1">
          <div class="col-sm-6 center-line">
            <img loading="lazy" src="{{data.value.img}}" alt="product" width="400">
            <div class="s-no">
              <span>{{i+1}}</span>
            </div>
          </div>
          <div class="col-sm-6 googleRanking">
            <div class="float">
              <h3>{{data.value.heading}}</h3>
              <p>{{data?.value?.features}}</p>
            </div>
          </div>
        </div>
        <div *ngIf="i % 2 != 0" class="row content-one mt-0">
          <div class="col-sm-6 localseoservice" >
            <div class="float-l">
              <h3>{{data.value.heading}}</h3>
              <p class="one-p">{{data?.value?.features}}</p>
            </div>
          </div>
          <div class="col-sm-6 center-line-l">
            <img loading="lazy" src="{{data.value.img}}" alt="product" width="400">
            <div class="s-no-l">
              <span>{{i+1}}</span>
            </div>
          </div>
        </div>
</ng-container>
  </section>

<!-- -------------------------our-business Section end----------------- -->

<!-- -------------------------our-business-mobile Section start----------------- -->
<section class="our-business-mobile">
  <div class="row  our-business-heading mt-0">
    <div class="col-sm-12">
      <h2>{{detail?.heading}} Features</h2>
    </div>
  </div>
  <ng-container *ngFor="let data of ourProducts | keyvalue let i=index;">
  <div *ngIf="i % 2 == 0" class="row content-one mt-5">
    <div class="col-sm-6 center-line">
      <img loading="lazy" src="{{data.value.img}}" alt="product">
      <div class="s-no">
        <span>{{i+1}}</span>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="container float">
        <h3>{{data.value.heading}}</h3>
        <p>{{data?.value?.features}}</p>
      </div>
    </div>
  </div>
  <div *ngIf="i % 2 != 0" class="row content-one mt-0">
    <div class="col-sm-6 center-line-l">
      <img loading="lazy" src="{{data.value.img}}" alt="product">
      <div class="s-no-l">
        <span>{{i+1}}</span>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="container float">
        <h3>{{data.value.heading}}</h3>
        <p class="one-p">{{data?.value?.features}}</p>
      </div>
    </div>
  </div>
</ng-container>
</section>
<!-- -------------------------our-business-mobile Section end----------------- -->
