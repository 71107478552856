import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-counting-number',
  standalone: true,
  imports: [],
  templateUrl: './counting-number.component.html',
  styleUrl: './counting-number.component.css'
})
export class CountingNumberComponent implements OnInit{
  @Input() targetValue: number = 0;
  currentValue: number = 0;

  constructor() { }

  ngOnInit(): void {
    this.startCounting();
  }

  startCounting(): void {
    const duration = 500; // Duration in milliseconds
    const interval = 10; // Update interval in milliseconds
    const step = Math.ceil(this.targetValue / (duration / interval));
    let count = 0;

    const timer = setInterval(() => {
      if (count < this.targetValue) {
        this.currentValue = Math.min(count + step, this.targetValue);
        count += step;
      } else {
        this.currentValue = this.targetValue;
        clearInterval(timer);
      }
    }, interval);
  }
}
