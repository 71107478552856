import { AfterContentChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { APIcalls } from '../Services/api';
import { APIService } from '../Services/main-service.service';


@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit, AfterContentChecked  {
  @ViewChild('view') elem !: ElementRef<HTMLDivElement>;
  formData: FormGroup;


  constructor(
    private api : APIService,
    private fb: FormBuilder,
    private router:Router,
    private title : Title
  ) {
    this.formData = this.fb.group(
      {
        Name : ['', [Validators.required, Validators.pattern('[a-zA-Z- ]*')]],
        Email : ['', [Validators.required, Validators.pattern(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)]],
        Number : ['', [Validators.required]],
        msg : ['', [Validators.required, Validators.minLength(10)]],
      }
    )
   }

  ngOnInit(): void {
    this.title.setTitle('Contact Us | Resiliencesoft')
  }

  ngAfterContentChecked(): void {
    const main = this.elem?.nativeElement.lastChild?.lastChild?.lastChild as HTMLAnchorElement
    if(main){
      main.style.visibility = 'hidden'
    }
  }

  noSpace(event:any) {
    if (event.keyCode === 32 && !event.target.value) return false;
    return true;
  }
  get fullName(){
    return this.formData.get('Name')
  }
  get email(){
    return this.formData.get('Email')
  }
  get contact(){
    return this.formData.get('Number')
  }
  get msg(){
    return this.formData.get('msg')
  }
  submit(number:any)
  {
    const formData = new FormData();
    formData.append('name', this.formData.get('Name')?.value)
    formData.append('email', this.formData.get('Email')?.value)
    formData.append('phone', this.formData.get('Number')?.value)
    formData.append('subject', 'Contact-us-msg')
    formData.append('message', this.formData.get('msg')?.value)
    this.formData.reset();
    number.reset();
    this.api.postData(APIcalls.mailApi.Contact, formData).subscribe({
      next: (res: any) => {
        this.api.openSnackBar(
          ' Form Has Been Successfully Submitted, We Will Contact You Soon ',
          5
        );

      },
      error: () => {
        this.api.openSnackBar(
          " We Can't Proceed Your Request at This Time ",
          2
        );
      }
  });
  }
}
