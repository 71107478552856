<!-- <div class="banner"></div>
 <div class="container">
    <div class="text-center font-weight-bold p-3" style="margin: 20px;font-size: 50px;"> OUR PRODUCTS</div>
        <h2 class="text-center"> Best Place to buy digital products.</h2>
        <div class="container">
            <div class="cardParent d-flex" style="flex-wrap: wrap;">
                <div class="card m-3" style="width: 25rem;" *ngFor="let data of array" >
                    <img loading="lazy" class="card-img-top img" src="../assets/images/ppr.jpg" alt="Card image cap">
                    <div class="card-body">
                      <h5 class="card-title title">Ecommerce</h5>
                      <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                      <a href="#" class="btn">View More Details</a>
                    </div>
                  </div>
            </div>
        </div>
</div> -->

<section>
  <div class="section main-con">
    <div class="banner">
      <div class="container">
        <h1 class="test">OUR PRODUCTS</h1>
        <p class="temp">
          Website Templates, App, and Graphics <b>Digital Marketplace</b>
        </p>
        <p class="place-to">BEST PLACE TO BUY DIGITAL PRODUCTS.</p>
        <p class="dev-product">WE HAVE CUSTOMISED DEVELOPMENT PRODUCT</p>
        <div class="row">
          <div class="col-md-6">
            <div class="d-flex all-product">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4  col-4 product " *ngFor="let detail of bannercardDetails" >
                  <div class="ecommerce">
                    <img loading="lazy" class="imgSize" src="{{detail.image}}" alt="product-detail" />
                    <p class="grocery-design">{{detail.title}}</p>
                  </div>
                </div>
            </div>
          </div>
          <div class="col-md-6">
            <img loading="lazy" class="bannerimg" src="../../assets/images/our-product-banner.png" alt="our products">
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div
        class="text-center heading font-weight-bold p-3">
        OUR PRODUCTS
      </div>
      <h2 class="text-center">Best Place to buy digital products.</h2>
      <div class="row">
        <div
          *ngFor="let card of cardDetails"
          class="col-xl-4 col-sm-4 col-md-6 col-4 col-12 mb-4"
        >
          <div class="our-product-card">
            <div class="product_card" [ngStyle]="getUrl(card)"></div>
            <div class="card-body">
              <h5 class="card-title">
                {{
                  card.tittle.length > 28
                    ? card.tittle.substring(0, 28) + "..."
                    : card.tittle
                }}
              </h5>
              <p class="card-text">{{ card.details }}</p>
              <div class="start-rate">
                <span  *ngFor="let star of getstarList(card.rating)">
                  <i  style="color: #ff9529" class="{{ star }}"></i>
                </span>
              </div>
              <a class="btn btn-primary buy_btn" (click)="gotoDetail(card)">View More Details</a>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>
      <!-- <div class="our-product">
        <div *ngFor="let card of cardDetails" class="m-3 our-product-card">
          <div class="product_card" [ngStyle]="getUrl(card)"></div>
          <div class="card-body">
            <h5 class="card-title">{{ card.tittle }}</h5>
            <p class="card-text">{{ card.details }}</p>
            <div class="start-rate">
              <span *ngFor="let star of starList">
                <i style="color: #ff9529" class="{{ star }} "></i>
              </span>
            </div>
            <a href="#" class="btn btn-primary buy_btn">View More Details</a>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</section>
