import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  ElementRef,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import * as lottie from 'lottie-web';
import { description } from '../SEO_Constant/seo';
import { title } from 'process';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css'],
})
export class AboutUsComponent implements OnInit {
  @ViewChild('myDOMElement', { static: true }) MyDOMElement?: ElementRef;
  picArray = new Array();

  // FOR OUR TEAM

  experts = [
    // {img: "assets/images/pankaj.jpg",tittle:"Pankaj Mishra" ,position:"Director & CEO"},
    // {img: "assets/images/prateek.jpg",tittle:"Prateek Saluja",position:"Director & CEO"},
    // {img: "https://images.prismic.io/kurmishadi/f9399423-6c8b-4843-8be2-8e2f2196184c_preeti.jpg?auto=compress,format",tittle:"Preeti sahu ",position:"HR"},//assets/images/team leaders/preeti.jpg
    {
      img: '/assets/images/image/sushant-for-team.webp',
      tittle: 'Sushant Saluja',
      position: 'Functional Manager',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/65de2683-a34a-4e43-8804-a5fef77bdd56_deepak.jpg?auto=compress,format',
      tittle: 'Deepak Verma',
      position: 'Manager',
    }, //assets/images/deepak.jpg
    {
      img: 'https://images.prismic.io/kurmishadi/45e2f9ca-80fb-4985-bc7b-86ab530e9db5_ankita.jpeg?auto=compress,format',
      tittle: 'Ankita choudhary ',
      position: 'Senior Angular Developer',
    }, //assets/images/team leaders/ankita.jpeg
    {
      img: 'https://images.prismic.io/kurmishadi/0fc62679-7b52-41c8-ae81-51d7ec8a01e7_irshad.jpg?auto=compress,format',
      tittle: 'Irshad Alam',
      position: 'Senior Web Designer',
    }, //assets/images/team leaders/irshad.jpg
    // {img: "https://images.prismic.io/kurmishadi/2f54afc1-8b71-4e08-a4e7-59084c31c2c7_swarna.jpg?auto=compress,format",tittle:"Swarna Tiwari",position:"Marketing Executive"},//assets/images/team leaders/swarna.jpg
    {
      img: 'https://images.prismic.io/kurmishadi/63828c9d-5c63-4022-a098-2ab1bc80e410_shreya.jpg?auto=compress,format',
      tittle: 'Shreya Gupta',
      position: 'Senior Software Developer',
    }, //assets/images/team leaders/mahendra.jpg
    {
      img: '/assets/images/image/manish.webp',
      tittle: 'Manish Chandra',
      position: 'Senior React  Developer',
    },
  ];

  expertsConfig = {
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1800,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  contriesConfig = {
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  // end

  constructor(private title: Title) {}

  ngOnInit(): void {
    this.title.setTitle('About | Resiliencesoft');

    for (let i = 1; i < 9; i++) {
      this.picArray.push({
        img_name: '/assets/images/our-teams/(' + i + ').svg',
        img_caption: 'Workplace Picture ' + i,
      });
    }
  }

  clientFeedback = [
    {
      description:
        'Resiliencesoft truly impressed us with their mobile app development skills. Our app was delivered on time and exceeded our expectations in terms of functionality and design. Highly recommended!',

      title: 'Sarah Johnson',
      country: 'USA',
    },
    {
      description:
        'We engaged Resiliencesoft for website development and were blown away by their professionalism and creativity. They understood our vision perfectly and brought it to life flawlessly. Great job!',
      title: ' David Thompson',
      country: 'Canada',
    },
    {
      description:
        'The team at Resiliencesoft is exceptional at product development. They helped us refine our concept into a market-ready product efficiently. Their attention to detail and commitment to quality are unmatched.',
      title: 'Ahmed Al-Mansoori',
      country: 'Saudi Arabia',
    },
    {
      description:
        "Resiliencesoft's Social Media management services have significantly boosted our online presence. They are proactive, responsive, and have a deep understanding of social media dynamics. We're thrilled with the results!",
      title: 'Isabella Bianchi',
      country: 'Australia',
    },
    {
      description:
        "Choosing Resiliencesoft for our Digital Media needs was one of the best decisions we made. Their strategic approach and innovative ideas have elevated our brand's visibility and engagement. Thank you!",
      title: 'Markus Müller',
      country: 'Germany',
    },
    {
      description:
        'Resiliencesoft has been instrumental in our mobile app development journey. They brought fresh ideas to the table and executed them with precision. Their technical expertise is commendable.',
      title: 'Emily Wilson',
      country: 'UK',
    },
    {
      description:
        "We are extremely satisfied with Resiliencesoft's website development services. They were quick to grasp our requirements and delivered a website that reflects our brand perfectly. Highly recommended!",
      title: 'Pieter van der Berg',
      country: 'Netherlands',
    },
    {
      description:
        "Kudos to Resiliencesoft for their outstanding product development skills. They turned our concept into reality seamlessly. The team's dedication and expertise are truly impressive.",
      title: 'Sophie Meier',
      country: 'Switzerland',
    },
    {
      description:
        "Resiliencesoft's Social Media management team is top-notch. They have significantly improved our social media engagement and brand awareness. It's been a pleasure working with them.",
      title: " Liam O'Brien",
      country: 'Ireland',
    },
    {
      description:
        "Our experience with Resiliencesoft for Digital Media services has been fantastic. They understand our brand's voice and have helped us connect with our audience effectively. We look forward to continuing this partnership!",
      title: 'Sophie Meier',
      country: 'Switzerland',
    },
  ];

  // FOR CLIENTS
  slideConfigmsg = {
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1800,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  countries = [
    {
      flag: 'https://images.prismic.io/kurmishadi/ZkW2wiol0Zci9MIQ_australia.webp?auto=compress,format',
      name: 'Australia',
    },
    {
      flag: 'https://images.prismic.io/kurmishadi/ZkW2uSol0Zci9MIP_canada.webp?auto=compress,format',
      name: 'Canada',
    },
    {
      flag: 'https://images.prismic.io/kurmishadi/ZkW2wiol0Zci9MIQ_australia.webp?auto=compress,format',
      name: 'Germany',
    },
    {
      flag: 'https://images.prismic.io/kurmishadi/ZkW2-yol0Zci9MIg_USA.webp?auto=compress,format',
      name: 'United States of America',
    },
    {
      flag: 'https://images.prismic.io/kurmishadi/ZkW28yol0Zci9MIe_UK.webp?auto=compress,format',
      name: 'United Kingdom',
    },
    {
      flag: 'https://images.prismic.io/kurmishadi/ZkW27iol0Zci9MIb_UAE.webp?auto=compress,format',
      name: 'United Arab Emirates',
    },
    {
      flag: 'https://images.prismic.io/kurmishadi/ZkW26Sol0Zci9MIZ_switzerland.webp?auto=compress,format',
      name: 'Switzerland',
    },
    {
      flag: 'https://images.prismic.io/kurmishadi/ZkW24iol0Zci9MIY_southi-arebia.webp?auto=compress,format',
      name: 'Saudi Arabia',
    },
    {
      flag: 'https://images.prismic.io/kurmishadi/ZkW22Sol0Zci9MIW_south-africa.webp?auto=compress,format',
      name: 'South Africa',
    },
    {
      flag: 'https://images.prismic.io/kurmishadi/ZkW20iol0Zci9MIU_netherlands.webp?auto=compress,format',
      name: 'Netherlands',
    },
    {
      flag: 'https://images.prismic.io/kurmishadi/ZkW2ziol0Zci9MIT_Ireland.webp?auto=compress,format',
      name: 'Ireland',
    },
  ];

  //   loadLottieAnimation(): void {
  //     const animationContainer = document.getElementById('lottie-container'); // Assuming you have a container with id 'lottie-container'
  //     const animationData = require('path/to/your/animation.json'); // Import animation JSON file
  //     const animation = lottie.loadAnimation({
  //         container: animationContainer,
  //         renderer: 'svg',
  //         loop: true,
  //         autoplay: true,
  //         animationData: animationData
  //     });
  // }

  //end
}
