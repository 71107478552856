import { KeyValue } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Validators } from "@angular/forms";
import { FormGroup, FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { APIcalls } from "src/app/Services/api";
import { APIService } from "src/app/Services/main-service.service";
// import { DialogContentConsultDialog } from '../page/consult-content';

@Component({
    selector: 'dialog-content-example-dialog',
    templateUrl: 'consult-content.html',
    styleUrls: ['../consult-now.component.css']
  })
  export class DialogContentConsultDialog implements OnInit{
    src = '/assets/images/welcome.jpg'
    id = '';
    data : any;
    formData : FormGroup = new FormGroup(
      {
        Name : new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z- ]*')]),
        Email : new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)]),
        Number : new FormControl('', [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(10), Validators.maxLength(10)]),
        msg : new FormControl('', [Validators.required, Validators.minLength(10)]),
      }
    )
      constructor( private api : APIService, private router:Router,public dialog: MatDialog){}
    ngOnInit(): void {
      this.id = localStorage.getItem('Dialogopen') as string;
      if ( this.id == 'welcomedialog'){
        this.src = '/assets/images/welcome.jpg'
      }
      if ( this.id == 'applyjob'){
        this.src = '/assets/images/job-apply.jpg'
      }
    }
    noSpace(event:any) {
      if (event.keyCode === 32 && !event.target.value) return false;
      return true;
    }


    get fullName(){
      return this.formData.get('Name')
    }

    get contact(){
      return this.formData.get('Number')
    }
    get email(){
      return this.formData.get('Email')
    }
    get msg(){
      return this.formData.get('msg')
    }

    SubmitEvent(){
    var formData: any = new FormData();
      formData.append('name', this.formData.value.Name)
      formData.append('email', this.formData.value.Email)
      formData.append('subject', 'Consult-now-msg')
      formData.append('phone', this.formData.value.Number)
      formData.append('message', this.formData.value.msg)
      this.formData.reset();
      this.api
      .postData(APIcalls.mailApi.Contact, formData)
      .subscribe({
      next: (res: any) => {
        this.api.openSnackBar(
          ' Form Has Been Successfully Submitted, We Will Contact You Soon ',
          5
        );

      },
      error: () => {
        this.api.openSnackBar(
          " We Can't Proceed Your Request at This Time ",
          2
        );
      }
  });
    }

  }
