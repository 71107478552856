import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { features } from 'process';
import { keyword } from '../SEO_Constant/seo';
// import { product, products } from '../constant';

@Component({
  selector: 'app-commonservice',
  templateUrl: './commonservice.component.html',
  styleUrls: ['./commonservice.component.css'],
})
export class CommonserviceComponent implements OnInit {
  id?: string;
  ourProducts: products = products;
  detail?: product;
  title: any;
  bannerArray: any = {
    bannerTXT: [
      {
        title:
          'Web design refers to the design of websites that are displayed on the internet.',
      },
      {
        title: 'Learning web design gives you the skills.',
      },
      {
        title: 'Designers also have a great scope in the media Industry.',
      },
      {
        title: 'Web design has numerous components.',
      },
    ],
    bannerImg: {
      banner: 'assets/img/web_design.png',
      backgroundImg: 'assets/img/banner-background.jpg',
    },
  };
  constructor(private route: ActivatedRoute, private router: Router) {
    route.params.subscribe((route) => {
      this.title = route['any'].replace(/-/g, ' ').replace(/[^a-zA-Z0-9\s]/g, '')
      if (router.url != '/') {
        let checkRoute = 0;
        Object.values(keyword).map((r) =>
          r.split(',').map((d) => {
            d.trim()
              .split(' ')
              .map((main) => {
                if (
                  main.trim().length >= 2 &&
                  router.url.toLowerCase().match(main.trim().toLowerCase())
                ) {
                  checkRoute++;
                }
              });
          })
        );
        if (!(checkRoute >= 2)) {
          router.navigate(['/']);
        }
      }
    });
  }

  ngOnInit(): void {
    // this.detail = this.ourProducts?.[this.route.snapshot.paramMap.get('name')]
    // this.bannerArray = this.ourProducts
  }
  typeOfObject(value: any) {
    return Object.keys(value).length > 0;
  }
}

export interface product {
  img: any;
  heading: string;
  features: any;
}

export interface products {
  [key: string]: product;
}
export const products: products = {
  website_Design: {
    img: 'assets/images/web_design_new.png',
    heading: 'website Design',
    features:
      'BEST Website Designing Agency Among The Leading Companies in India Developing website designs that connect with the users requires a thorough understanding of the medium and a creative mindset. Here are some factors why you should consider us for your website design:. In the business of developing creative websites Experienced Coders, UI designers and content developers. Specialized in Portal and Ecommerce website design. Smart integration of social media tools. Masters in optimizing websites to rank well on search engines.Special team for support and website maintenance.',
  },
  software_Development: {
    img: 'assets/images/web-development_new.png',
    heading: 'software Development',
    features:
      'BEST Web Development Agency Among The Leading Companies in India Our website development services have led to a results driven process that ensures successful outcomes. Our development process starts with the analysis, research and planning followed by strong SEO driven content and collaborative design. Quality Web Content Content should include important information and come in the forms that are pertinent to the website. Sensible Web design Balanced distribution of content and graphics is required and the use of slightly contrasting colours and clear fonts is key. CMS Websites Content management system complements a website by providing website owners with the tools to manage their website’s content themselves.',
  },
  Web_Application_Development: {
    img: 'assets/images/web-application_new.png',
    heading: 'Web Application Development',
    features:
      'BEST Web Application Development Agency Among The Leading Companies in India Web application development is the creation of application programs that reside on remote servers and are delivered to the user’s device over the Internet. A web application (web app) does not need to be downloaded and is instead accessed through a network. An end user can access a web application through a web browser such as Google Chrome, Safari, or Mozilla Firefox. A majority of web applications can be written in JavaScript, Cascading Style Sheets (CSS), and HTML5..',
  },
  web_Hosting_Solutions: {
    img: 'assets/images/web-hosting_design.png',
    heading: 'web Hosting Solutions',
    features:
      'BEST Web Hosting Solutions Agency Among The Leading Companies in India Resiliencesoft Web Hosting solutions enable its clients to adeptly host a website with full cPanel access along with many additional websites. With highly advanced tools, technical support and experienced team of technical professionals by our side, we feel fully equipped to offer our clients the most stable and secure shared Hosting environment. In addition we present to our clients the choice of operating system and truly affordable, feature-rich shared hosting plans to meet core business requirements of all-sizes businesses. Our Web Hosting Features are: 99.99 % Uptime gurantee.  Flexible and Easy to Use Control Panel. Servers are located in India itself for lowest latency and fastest access times. Readymade free script ready to install in your account by few clicks. Webmail Access: Horde, Squirelmail, RoundCube etc. Receive Your corporate mail on your phone with IMAP support. Online Support Portal 24x7 with ready tutorials for your quick reference. and many more....',
  },
  android_app_development: {
    img: 'assets/images/mobile-app-iocn.png',
    heading: 'Android App Development',
    features:
      'BEST Android App Development Agency Among The Leading Companies in India Resiliencesoft is a skillful and versatile mobile development team that delivers outstanding Android apps for B2B/B2E and B2C markets. Our applications help businesses expand their reach to millions of Android users, create new profit centers and set them apart from the competition. Let’s see if our Android mobile application development services match your needs . Our Android application developers are always aware of the clients need. We help our clients pull out the best from the platform’s capabilities and build smooth experiences ..',
  },
  ios_app_development: {
    img: 'assets/images/mobile-application_app.png',
    heading: 'Ios App Development',
    features:
      'BEST Ios App Development Agency Among The Leading Companies in India We’re an Apple of Every App Lover At Resiliencesoft , we master the art of conceptualizing, designing and creating cool App Store applications, built and developed around iOS framework. We present tailor-made solutions for iOS operatives, and weave simplicity and user-friendly app structure for iPhone, iPad and iPod Touch. We develop uniform iOS applications that synchronizes well with Mac too. Our experts not only understand and thoroughly research for your applications, we make sure the applications we develop remain free from issues. We draw inspiration from your web-based infrastructure and customize the applications. We tune excellence into all our iOS applications to enable maximum downloads and likes from the end users. Our expertise lies in simplified presentation of iOS programming language Objective-C. Our efforts remain focused on building brilliantly featured apps through smart use of Xcode, an Integrated Development Environment (IDE) for Apple’s phone and Mac operating systems..',
  },
  hybrid_app_development: {
    img: 'assets/images/hybrid-app.png',
    heading: 'Hybrid App Development',
    features:
      'BEST Hybrid Web App Agency Among The Leading Companies in India The potential for substantial growth and success with a well thought out, designed, Attractive UX/UI Our designers create a Hybrid app that is aesthetically appealing and interactive. The Hybrid application that we create loads faster and provides seamless functionality. They are efficient without the support of network communication. Our experts develop a hybrid application with an optimum user experience guarantee.',
  },
  native_app_development: {
    img: 'assets/images/app-development-rn.png',
    heading: 'Native App Development',
    features:
      'BEST Native App Development Agency Among The Leading Companies in India iOS Apps Development- Swift 4 and earlier versions Android Mobile Apps Development- Java Extending Native Plug-ins Knowledge about all native IDE Knowledge of various UI style conventions and standards.',
  },
  search_Engine_Optimisation: {
    img: 'assets/img/our-business.png',
    heading: 'search Engine Optimisation',
    features:
      'Our Local SEO services can help you grow your online visibility within your target locations. Get more traffic and business with our tried and tested local SEO plans. We target "near me" and geo-specific search queries to make sure your business ranks high for local search queries. Our local SEO packages bring tons of business benefits including a boost in online visibility, more traffic to your brick-and-mortar location and the opportunity to enhance your brand’s credibility and trust.',
  },
  digital_Media_Marketing: {
    img: 'assets/images/digital-margketing_new.png',
    heading: 'Digital Media Marketing',
    features:
      'BEST Digital Media Maketing Agency Among The Leading Companies in India We are here to make brands grow and thrive in today’s competitive and changing markets. We are here to ride the big Digital wave; offering our clients value-for-money ideas, where each spend is justified and fruitful. Led by top-notch professionals who have shone in the fields of Advertising, Creativity, and Digital Marketing, we are a strong team of experts who can synergize their talents and experience to create powerful campaigns that work',
  },
  social_Media_Promotion: {
    img: 'assets/images/social-media-promotion.png',
    heading: 'social Media Promotion',
    features:
      'BEST Social Media Promotion Agency Among The Leading Companies in India Social media marketing campaigns have the advantage of appealing to a broad audience at once. For example, a campaign may appeal to current and prospective customers, employees, bloggers, the media, the general public, and other stakeholders, such as third-party reviewers or trade groups. Social media marketing is the process of creating content for social media platforms to promote your products and/or services, build community with your target audience, and drive traffic to your business. With new features and platforms emerging every day, social media marketing is constantly evolving.',
  },
  bulk_Sms_and_Email_Marketing: {
    img: 'assets/images/bulk-sms-marketing.png',
    heading: 'Bulk SMS And Email Marketing',
    features:
      'BEST Bulk Sms And Email Maketing Agency Among The Leading Companies in India Easily send powerful bulk SMS campaigns Effortlessly personalize your bulk SMS with names or custom fields, compose in 20+ languages, schedule/stagger your sends, add opt-out path and more. Get best-in-class SMS delivery. Create bulk SMS campaigns your customers love Convey more with SMS attachments Easily insert images, videos, PDFs, spreadsheets & more as shortlinks in your bulk SMS campaigns grab your customers’ attention. Convey more in 160 characters. Send files via bulk SMS Access real-time delivery and click reports Measure campaign outreach and effectiveness with real-time SMS delivery reports, and granular click metrics for all weblinks and attachments sent as Textlocal shortlinks. Track SMS campaign metrics 5-minute SMS API integration Automate sending and receiving SMS from any website, CRM, or application. Access SMS APIs with sample codes in 10+ languages including PHP, Java, .NET & more. Know more about Textlocal SMS APIs Receive SMS online Let your customers instantly reach you by just texting into your long codes and keywords. Set auto-replies or take the conversation forward on other channels. Enable two-way SMS communication Manage and create emails with a single, integrated platform Create rich, personalized transactional and marketing emails, and send them via API or web portal, to capture your customers’ attention.',
  },
  logo_Design: {
    img: 'assets/images/logo-design.png',
    heading: 'logo Design',
    features:
      'BEST Logo Design Agency Among The Leading Companies in India A well-designed logo builds trust by validating your professionalism and get’s people to stick around. It tells potential clients who you are, what you do, and how that benefits them. It communicates to people with no prior knowledge or experience with your business that you do great work. A logo can quickly grab viewers’ attention and communicate a company’s core values in an interesting way. That short attention span you know, the one that causes consumers to judge your business by its appearance, can work to your advantage, if you have a solid logo to speak for your company',
  },
  graphic_Design: {
    img: 'assets/img/Graphic_Design.png',
    heading: 'Graphic Design',
    features:
      'BEST Graphic Design Agency Among The Leading Companies in India Graphic design is a craft where professionals create visual content to communicate messages. By applying visual hierarchy and page layout techniques, designers use typography and pictures to meet users specific needs and focus on the logic of displaying elements in interactive designs, to optimize the user experience. Graphic design is defined as the art and practice of planning and projecting ideas and experiences with visual and textual content. In other terms, graphic design communicates certain ideas or messages in a visual way. These visuals can be as simple as a business logo, or as complex as page layouts on a website.',
  },
  digital_Visiting_Card: {
    img: 'assets/img/Digital_Visiting.png',
    heading: 'Digital Visiting Card',
    features:
      'BEST Digital Visiting Card Agency Among The Leading Companies in India A digital visiting card is the virtual version of a paper visiting card. Digital visiting cards can be sent and received by anyone, anywhere, digital cards contain more information, digital business cards are cost-effective and eco-friendly. Not only does it convey important personal contact information such as name, title, email, website, address and phone number, but oftentimes it is also the first exposure to the overall image of the business. The company logo is prominently displayed for brand identity.',
  },
  video_Creation: {
    img: 'assets/img/video.png',
    heading: 'video Creation',
    features:
      'BEST Video Creation Agency Among The Leading Companies in India A creator is someone who is responsible for the contribution of information to any media and most especially to digital media. They usually target a specific end-user / audience in specific contexts. Videos let you establish authority and a more personal feel to your message. You will be far more likely to connect on an emotional level with your audience if you use video versus another content type.',
  },
};
