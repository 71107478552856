<div class="page_heading">
    <div class="container">
        <h2>{{detail?.heading}}</h2>
    </div>
</div>
<app-banner [BannerData]="bannerArray"></app-banner>


<!-- -------------------------our-business Section Start----------------- -->
<section class="our-business">
    <div class="row  our-business-heading mt-0">
      <div class="col-sm-12">
        <h2>{{detail?.heading}} Features</h2>
        <!-- <span>Top Rated SEO Agency in India</span> -->
      </div>
    </div>
    <div class="row content-one mt-5">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="{{detail?.img?.img1}}" alt="product-detail">
        <div class="s-no">
          <span>01</span>
        </div>
      </div>
      <div class="col-sm-6 googleRanking">
        <div class="float">
          <h3>{{detail?.features?.featureDetail?.title1 }}</h3>
          <p>{{detail?.features?.featureDetail?.features1}}</p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 localseoservice" >
        <div class="float-l">
          <h3>{{detail?.features?.featureDetail?.title2}}</h3>
          <p class="one-p">{{detail?.features?.featureDetail?.features2}}</p>
        </div>
      </div>
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="{{detail?.img?.img2}}" alt="product-detail">
        <div class="s-no-l">
          <span>02</span>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="{{detail?.img?.img3}}" alt="product-detail">
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6 googlePenalty">
        <div class="float">
          <h3>{{detail?.features?.featureDetail?.title3}}</h3>
          <p>{{detail?.features?.featureDetail?.features3}}</p>
        </div>
      </div>
    </div>
  </section>
  <!-- -------------------------our-business Section end----------------- -->

  <!-- -------------------------our-business-mobile Section start----------------- -->
  <section class="our-business-mobile">
    <div class="row  our-business-heading mt-0">
      <div class="col-sm-12">
        <h2>{{detail?.heading}} Features</h2>
        <!-- <span>Top Rated SEO Agency in India</span> -->
      </div>
    </div>
    <div class="row content-one mt-5">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="{{detail?.img?.img1}}" alt="product-detail">
        <div class="s-no">
          <span>01</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float">
          <h3>{{detail?.features?.featureDetail?.title1}}</h3>
          <p>{{detail?.features?.featureDetail?.features1}}</p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="{{detail?.img?.img2}}" alt="product-detail">
        <div class="s-no-l">
          <span>02</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float">
          <h3>{{detail?.features?.featureDetail?.title2}}</h3>
          <p class="one-p">{{detail?.features?.featureDetail?.features2}}</p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="{{detail?.img?.img3}}" alt="product-detail">
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float mb-3">
          <h3>{{detail?.features?.featureDetail?.title3}}</h3>
          <p>{{detail?.features?.featureDetail?.features3}}</p>
        </div>
      </div>
    </div>
  </section>
  <!-- -------------------------our-business-mobile Section end----------------- -->
