import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './blocks/header/header.component';
import { FooterComponent } from './blocks/footer/footer.component';
import {
  LocationStrategy,
  HashLocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { PaymentComponent } from './payment/payment.component';
import { CareersComponent } from './careers/careers.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { CallBackComponent } from './call-back/call-back.component';
import { GalleryComponent } from './gallery/gallery.component';
import { ConsultNowModalComponent } from './models/consult-now/button/consult-now-modal.component';
import { DialogContentConsultDialog } from './models/consult-now/page/consult-content';
import { DialogContentContactBTNDialog } from './models/Contact-btn/page/contact-btn';
import { ContactBtnModalComponent } from './models/Contact-btn/button/contact-btn.component';
import { FloatingWhatsappComponent } from './floating-whatsapp/floating-whatsapp.component';
import { MatNativeDateModule } from '@angular/material/core';
import { GalleryComponentModel } from './models/gallery/gallery.component';
import { LoadingSpinner } from './models/Spinner/spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TrustBtnModalComponent } from './models/Contact-btn copy/button/trusted.component';
import { TrustedBTNDialog } from './models/Contact-btn copy/page/trusted';
import { MeettheteamComponent } from './meettheteam/meettheteam.component';
import { OurProductsComponent } from './our-products/our-products.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { SharedModule } from './shared/shared.module';
import { CommonserviceComponent } from './commonservice/commonservice.component';
import { UserReviewComponent } from './user-review/user-review.component';
import { InternshipComponent } from './internship/internship.component';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { provideLottieOptions } from 'ngx-lottie';
import player from 'lottie-web';
import { CountingNumberComponent } from "./counting-number/counting-number.component";
import { ContractualServiceComponent } from './contractual-service/contractual-service.component';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        HeaderComponent,
        FooterComponent,
        PaymentComponent,
        CareersComponent,
        PortfolioComponent,
        ContactUsComponent,
        AboutUsComponent,
        CallBackComponent,
        ConsultNowModalComponent,
        DialogContentConsultDialog,
        GalleryComponent,
        DialogContentContactBTNDialog,
        ContactBtnModalComponent,
        GalleryComponentModel,
        FloatingWhatsappComponent,
        LoadingSpinner,
        TrustBtnModalComponent,
        TrustedBTNDialog,
        MeettheteamComponent,
        OurProductsComponent,
        ProductDetailsComponent,
        CommonserviceComponent,
        UserReviewComponent,
        InternshipComponent,
        ContractualServiceComponent
    ],
    providers: [
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        // {
        //   provide: HTTP_INTERCEPTORS,
        //   useClass: ApiInterceptor,
        //   multi : true
        // },
        provideLottieOptions({
            player: () => player,
        }),
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        SlickCarouselModule,
        FormsModule,
        BrowserAnimationsModule,
        MatTabsModule,
        SharedModule,
        MatDialogModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatSnackBarModule,
        NgxMatIntlTelInputComponent,
        CountingNumberComponent,
    ]
})
export class AppModule {}
