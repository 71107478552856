<div class="Gallery-container">
    <div class="Gallery">
        <div class="Gallery-img" *ngFor="let Data of $any(GalleryData);let i = index">
            <div >
                <img loading="lazy" (click)="PreviewImage(i)" [src]="Data.img_name" [alt]="Data.img_caption">
            </div>
            <!-- *ngIf="i <= 5" -->
            <!-- <div *ngIf="i > 5 && i<=10">
                <img loading="lazy" (click)="PreviewImage(i)" [src]="Data.img_name" [alt]="Data.img_caption">
            </div>
            <div *ngIf="i > 10 && i<=15">
                <img loading="lazy" (click)="PreviewImage(i)" [src]="Data.img_name" [alt]="Data.img_caption">
            </div>
            <div *ngIf="i > 15 && i<=20">
                <img loading="lazy" (click)="PreviewImage(i)" [src]="Data.img_name" [alt]="Data.img_caption">
            </div>
            <div *ngIf="i > 20 && i<=25">
                <img loading="lazy" (click)="PreviewImage(i)" [src]="Data.img_name" [alt]="Data.img_caption">
            </div>
            <div *ngIf="i > 25 && i<=30">
                <img loading="lazy" (click)="PreviewImage(i)" [src]="Data.img_name" [alt]="Data.img_caption">
            </div>
            <div *ngIf="i > 30 && i<=35">
                <img loading="lazy" (click)="PreviewImage(i)" [src]="Data.img_name" [alt]="Data.img_caption">
            </div>
            <div *ngIf="i > 35 && i<=41">
                <img loading="lazy" (click)="PreviewImage(i)" [src]="Data.img_name" [alt]="Data.img_caption">
            </div> -->
        </div>
    </div>
    <div *ngIf="showmask" class="buttons" @animation2>
        <span *ngIf="showCount" class="count">{{currentIndex+1}}</span>
        <button *ngIf="previewImage" (click)="closePreview()" class="close-btn">
            <i class="fa fa-times"></i>
        </button>
        <div *ngIf="controls" class="btn-indicate btn-prev" (click)="prevImage()">
            <i class="fas fa-arrow-circle-left icon-buttons icon-left"></i>
        </div>
        <div *ngIf="controls" class="btn-indicate btn-next" (click)="nextImage()">
            <i class="fas fa-arrow-circle-right icon-buttons icon-right"></i>
        </div>
        <div *ngIf="previewImage" [@animation]="{value: 'visible'}" (@animation.done)="AnimationEnd($event)"
            class="Image">
            <img loading="lazy" [src]="currentLightboximg.img_name" [alt]="currentLightboximg.img_caption">
        </div>
    </div>
</div>
